import { API, graphqlOperation } from 'aws-amplify'

export interface ReceivableOrder {
  CustName: string
  ExpectedDate: string
  ReceivedDate?: string
  CountedDate?: string
  ReceivableOrderID: number
  Reference: string
  Status: number
  Supplier: string
  ContainerSize: number
  SiteID: number
}

const GET_RECEIVABLE_ORDERS = `query getReceivableOrders($status: Int, $siteID: String) {
    getReceivableOrders(status: $status, siteID: $siteID) {
      CustName
      ExpectedDate
      ReceivedDate
      CountedDate
      ReceivableOrderID
      Reference
      Comment
      Status
      Supplier
      ContainerSize
      SiteID
    }
}`

interface GET_RECEIVABLE_ORDERS_RESULT {
  data: {
    getReceivableOrders: ReceivableOrder[]
  }
}

export async function GetReceivableOrders(status?: number, siteID?: number): Promise<ReceivableOrder[]> {
  let ReceivableOrders: ReceivableOrder[] = []
  try {
    const result = (await API.graphql(graphqlOperation(GET_RECEIVABLE_ORDERS, { siteID, status }))) as GET_RECEIVABLE_ORDERS_RESULT
    result.data.getReceivableOrders.forEach(element => {
      element.SiteID = Number(element.SiteID) // There is only one site available at the moment from the API, so setting it as that
    }) // TODO: Remove once sites have been added to results
    ReceivableOrders = result.data.getReceivableOrders
  } catch (e) {
    console.log(e)
  }
  return ReceivableOrders
}
