import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material'
import { Close } from '@mui/icons-material'
import React from 'react'
import { submitHandlingTime } from '../../../graphQL/ReceivablesHandlingTime'
import {
  setProviderLocalHandlingTime,
  useReceivableOrderDispatch,
  useReceivableOrderState
} from '../../../providers/ReceivableOrdersProvider'
import {
  addHandlingTime,
  setSnackbarNotification,
  setOpenTimeHandlingDialog,
  useReceivablesFormDispatch,
  useReceivablesFormState
} from '../../../providers/ReceivablesFormProvider'
import { colors as qmColors } from '../../../theme/colorPalette'

export default function HandlingTimeDialog() {
  // const classes = useStyles()
  const { receivablesForm, openTimeHandlingDialog } = useReceivablesFormState()
  const { providerLocalHandlingTime } = useReceivableOrderState()
  const dispatch = useReceivablesFormDispatch()
  const orderDispatch = useReceivableOrderDispatch()
  const [isLoading, setIsLoading] = React.useState(false)

  function selectTime(event: SelectChangeEvent<number>) {
    setProviderLocalHandlingTime(orderDispatch, event.target.value as number)
  }

  const closeDialog = () => {
    setOpenTimeHandlingDialog(dispatch, false)
    setProviderLocalHandlingTime(orderDispatch, 0)
  }

  const onConfirm = async () => {
    try {
      setIsLoading(true)
      const newHandlingTime = await submitHandlingTime(Number(receivablesForm?.order.ReceivableOrderID), providerLocalHandlingTime)
      addHandlingTime(dispatch, newHandlingTime)
      setProviderLocalHandlingTime(orderDispatch, 0)
      setIsLoading(false)
      setSnackbarNotification(dispatch, true, 'success', 'Handling Time Added')
    } catch (e) {
      setSnackbarNotification(dispatch, true, 'error', 'Error adding handling time')
    }
    closeDialog()
  }

  const DialogTitle = (props: any) => {
    const { children, ...other } = props
    return (
      <MuiDialogTitle {...other} sx={{ background: qmColors.qmBlue60, marginBottom: '10px' }}>
        <Typography sx={{ color: '#FFFFFF', fontSize: '18px', fontWeight: 600 }}>{children}</Typography>
        <IconButton
          aria-label="close"
          sx={(theme) => ({ position: 'absolute', right: theme.spacing(1), top: theme.spacing(1), color: '#FFFFFF' })}
          onClick={closeDialog}
          size="large"
        >
          <Close />
        </IconButton>
      </MuiDialogTitle>
    )
  }

  return (
    <>
      {openTimeHandlingDialog
        ? (
        <Dialog maxWidth="lg" scroll="paper" open={true} onClose={closeDialog}>
          <DialogTitle>Add time</DialogTitle>
          <DialogContent sx={{ padding: '8px 15px', display: 'flex', justifyContent: 'center' }}>
            {isLoading && <CircularProgress />}
            {!isLoading && (
              <FormControl variant="outlined" fullWidth>
                <Select name="countingTime" defaultValue={0} onChange={selectTime} data-testid={'handlingTime-select'}>
                  <MenuItem value={0}>0 minutes</MenuItem>
                  <MenuItem value={15} data-testid={'15 minutes'}>
                    15 minutes
                  </MenuItem>
                  <MenuItem value={30}>30 minutes</MenuItem>
                  <MenuItem value={45}>45 minutes</MenuItem>
                  <MenuItem value={60}>60 minutes</MenuItem>
                  <MenuItem value={75}>75 minutes</MenuItem>
                  <MenuItem value={90}>90 minutes</MenuItem>
                  <MenuItem value={105}>105 minutes</MenuItem>
                  <MenuItem value={120}>2 hours</MenuItem>
                  <MenuItem value={180}>3 hours</MenuItem>
                  <MenuItem value={240}>4 hours</MenuItem>
                  <MenuItem value={300}>5 hours</MenuItem>
                  <MenuItem value={360}>6 hours</MenuItem>
                  <MenuItem value={420}>7 hours</MenuItem>
                  <MenuItem value={480}>8 hours</MenuItem>
                  <MenuItem value={540}>9 hours</MenuItem>
                  <MenuItem value={600}>10 hours</MenuItem>
                  <MenuItem value={660}>11 hours</MenuItem>
                  <MenuItem value={720} data-testid={'12 hours'}>
                    12 hours
                  </MenuItem>
                  <MenuItem value={1440}>24 hours</MenuItem>
                </Select>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions sx={{ margin: '0 7px' }}>
            {!isLoading && (
              <>
                <Button onClick={closeDialog} variant="outlined">
                  Cancel
                </Button>
                <Button onClick={onConfirm} variant="contained" disabled={!providerLocalHandlingTime}>
                  Add time
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
          )
        : null}
    </>
  )
}
