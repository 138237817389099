import {
  Dialog,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography
} from '@mui/material'
import { Close } from '@mui/icons-material'
import React, { FC, ReactNode } from 'react'
import { ImagePreview } from './getImageURLs'
import { colors as qmColors } from '../../../theme/colorPalette'

interface PhotoInformationProps {
  open: boolean
  closeDialog: () => void
  photoInfo: ImagePreview
}

interface DialogTitleProps {
  children?: ReactNode
}

const PhotoInformation: React.FC<PhotoInformationProps> = ({ open, closeDialog, photoInfo }) => {
  const DialogTitle: FC<DialogTitleProps> = ({ children }) => {
    return (
      <MuiDialogTitle sx={{ background: qmColors.qmBlue60, marginBottom: '10px' }}>
        <Typography color='#FFFFFF' fontSize='18px' fontWeight={600}>{children}</Typography>
        <IconButton
          aria-label="close"
          sx={(theme) => ({
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: '#FFFFFF'
          })}
          onClick={closeDialog}
          size="large">
          <Close />
        </IconButton>
      </MuiDialogTitle>
    )
  }

  return (
    <Dialog maxWidth="md" scroll="paper" open={open} onClose={closeDialog}>
      <DialogTitle>Image Details</DialogTitle>
      <DialogContent sx={{
        padding: '10px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '30px'
      }}>
        <div>
          <img id="selected-image" src={photoInfo.url} style={{ maxWidth: '525px' }} alt="selected" />
        </div>
        <div>
          <div style={{ marginBottom: '15px' }}>
            <Typography fontWeight={600} fontSize='14px' lineHeight='21px' >Description</Typography>
            <Typography fontWeight={400} fontSize='14px' lineHeight='17px' color={qmColors.grey50}>{photoInfo.FileName}</Typography>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <Typography fontWeight={600} fontSize='14px' lineHeight='21px' >Date &amp; Time</Typography>
            <Typography fontWeight={400} fontSize='14px' lineHeight='17px' color={qmColors.grey50}>{photoInfo.UploadedDate}</Typography>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <Typography fontWeight={600} fontSize='14px' lineHeight='21px' >Uploaded By</Typography>
            <Typography fontWeight={400} fontSize='14px' lineHeight='17px' color={qmColors.grey50}>{photoInfo.UploadedBy}</Typography>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default PhotoInformation
