import { CssBaseline } from '@mui/material'
import { Amplify } from 'aws-amplify'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import awsconfig from './aws-exports'
import Routes from './pages/Routes'
import Providers from './providers/ProvidersWrapper'

Amplify.configure(awsconfig)

const App: React.FC = () => {
  return (
    <Providers>
      <Router>
        <CssBaseline />
        <Routes />
      </Router>
    </Providers>
  )
}

export default App
