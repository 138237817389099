import React, { FC } from 'react'
import HeaderSaveButton from '../../pages/ReceivablesForm/ActionButtons'
import HeaderEmailButton from '../../pages/ReceivablesForm/ActionButtons/EmailButton'
import { HeaderAddHandlingTimeButton } from '../../pages/ReceivablesForm/AddHandlingTime'
import { HeaderAddNoteButton } from '../../pages/ReceivablesForm/Comments'
import { colors as qmColors } from '../../theme/colorPalette'
import { styled } from '@mui/material'

const StyledDiv = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  '& div': {
    minWidth: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const ReceivableMenuButtons: FC = () => {
  return (
    <StyledDiv>
      <div style={{ background: qmColors.qmBlue }}>
        <HeaderAddNoteButton />
      </div>
      <div style={{ background: qmColors.brightBlue }}>
        <HeaderAddHandlingTimeButton />
      </div>
      <div style={{ background: qmColors.teal }}>
        <HeaderEmailButton />
      </div>
      <div style={{ background: qmColors.green }}>
        <HeaderSaveButton />
      </div>
    </StyledDiv>
  )
}

export default ReceivableMenuButtons
