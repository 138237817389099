import { createTheme, Shadows } from '@mui/material/styles'
import { palette } from './theme/colorPalette'
import { overrides } from './theme/overrides'
import { typography } from './theme/typography'

const theme = createTheme({
  typography,
  palette,
  components: overrides,
  shadows: Array(25).fill('none') as Shadows
})

export { theme }
