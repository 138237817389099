import { Grid, Typography, styled } from '@mui/material'
import React from 'react'
import { useReceivablesFormState } from '../../../providers/ReceivablesFormProvider'
import { wamDateStringFormat } from '../../../utils'
import PalletInfo from '../PalletInfo'

export function validateDate(inDate: String): boolean {
  let retBool = false
  if (!(inDate === '0000-00-00' || inDate === '3000-01-01' || inDate === '' || inDate === null || inDate === undefined)) {
    retBool = true
  }
  return retBool
}

const StyledTypography = styled(Typography)({
  fontSize: '1.5rem',
  fontWeight: 600
})

export default function Header() {
  const { receivablesForm } = useReceivablesFormState()
  const orderStatus = receivablesForm?.order.Status || 1
  const displayDate =
    orderStatus === 1
      ? `Expected: ${wamDateStringFormat(receivablesForm?.order.ExpectedDate)}`
      : orderStatus === 2
        ? `Received: ${wamDateStringFormat(receivablesForm?.order.ReceivedDate)}`
        : orderStatus === 3
          ? `Approved: ${wamDateStringFormat(receivablesForm?.order.CountedDate)}`
          : 'n/a'
  const comment = receivablesForm?.order.Comment || 'No comments made...'

  return (
    <>
      <Grid container spacing={2} style={{ margin: 0 }}>
        <Grid item xs={8} style={{ padding: '8px 8px 8px 0px' }}>
          <StyledTypography sx={(theme) => ({ color: theme.palette.primary.main })}>{receivablesForm?.order.CustName}</StyledTypography>
        </Grid>
        <Grid item xs={4} style={{ padding: 8 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '15px', flexWrap: 'wrap' }}>
            <StyledTypography>Order #: {receivablesForm?.order.ReceivableOrderID}</StyledTypography>
            <StyledTypography data-testid={'header-orderStatus'}>
              {displayDate}
            </StyledTypography>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: '0.9em', marginBottom: '5px' }}>Comments</span>
            <span>{comment}</span>
          </div>
        </Grid>
        <Grid item xs={4}>
          <PalletInfo />
        </Grid>
      </Grid>
    </>
  )
}
