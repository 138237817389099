import { isEqual } from 'lodash'
import React from 'react'
import { Comments } from '../graphQL/ReceivablesComments'
import { ReceivableForm, Storage } from '../graphQL/ReceivablesForm'
import { ReceivableStatus } from '../models/ReceivableStatus'
import { HandlingTime } from '../graphQL/ReceivablesHandlingTime'
import { ImagePreview } from '../pages/ReceivablesForm/Photos/getImageURLs'

export interface TableFieldProps {
  name: string
  value: string | number | boolean | null
  checked?: boolean
}

interface SnackbarNotification {
  message?: string
  severity?: 'error' | 'success' | 'info' | 'warning'
  open: boolean
}

const defaultSnackbar: SnackbarNotification = {
  message: undefined,
  severity: 'info',
  open: false
}

type Action =
  | { type: 'setReceivablesForm'; payload: { receivablesForm?: ReceivableForm } }
  | { type: 'setUserEmail'; payload: { email: string } }
  | { type: 'setPalletInfo'; payload: { field: string; value: any } }
  | { type: 'setPhotos'; payload: { photos: ImagePreview[] } }
  | { type: 'setTableFields'; payload: { tableField: TableFieldProps } }
  | { type: 'setReceivableItemExpiryDate'; payload: { receivableId: number, expiryDate: Date | null } }
  | { type: 'addComment'; payload: { comment: Comments } }
  | { type: 'editComment'; payload: { receivableOrderCommentId: number, comment: string } }
  | { type: 'deleteComment'; payload: { receivableOrderCommentId: number } }
  | { type: 'addHandlingTime'; payload: { inHandlingTime: HandlingTime } }
  | { type: 'updateHandlingTime'; payload: { inHandlingTime: HandlingTime } }
  | { type: 'deleteHandlingTime'; payload: { handlingTimeId: number } }
  | { type: 'setIsLoading'; payload: { value: boolean } }
  | { type: 'setSnackbarNotification'; payload: { message?: string; severity?: 'error' | 'success' | 'info' | 'warning'; open: boolean } }
  | { type: 'setOpenEmailDraftDialog'; payload: { open: boolean } }
  | { type: 'setShowEmailSentNotification'; payload: { show: boolean } }
  | { type: 'setSelectedReceivableID'; payload: { selectedReceivableID: number | undefined } }
  | { type: 'setOpenNoteDialog'; payload: { open: boolean } }
  | { type: 'setCommentTextForReceivableId'; payload: { selectedReceivableID: number } }
  | { type: 'setOpenTimeHandlingDialog'; payload: { open: boolean } }
  | { type: 'setOpenPrintDialog'; payload: { open: boolean } }
  | { type: 'setOpenFileSelectDialog'; payload: { open: boolean } }
  | { type: 'storeUnsavedForm' }
  | { type: 'loadUnsavedForm' }

export type Dispatch = (action: Action) => void
type State = {
  receivablesForm?: ReceivableForm
  receivablesFormCopy?: ReceivableForm
  userEmail?: string
  formChanged?: boolean
  isLoading: boolean
  snackbarNotification: SnackbarNotification
  openEmailDraftDialog: boolean
  showEmailSentNotification: boolean
  selectedReceivableID?: number
  selectedReceivableIDForComment?: number
  openNoteDialog: boolean
  openTimeHandlingDialog: boolean
  openPrintDialog: boolean
  openFileSelectDialog: boolean
}

type Props = { children: React.ReactNode; receivablesForm?: ReceivableForm | undefined }

const ReceivablesFormStateContext = React.createContext<State | undefined>(undefined)
const ReceivablesFormDispatchContext = React.createContext<Dispatch | undefined>(undefined)

function receivablesFormReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setReceivablesForm': {
      return {
        ...state,
        receivablesForm: action.payload.receivablesForm,
        receivablesFormCopy: action.payload.receivablesForm,
        isLoading: false,
        formChanged: false,
        snackbarNotification: defaultSnackbar,
        selectedReceivableID: undefined
      }
    }

    case 'setUserEmail': {
      return {
        ...state,
        userEmail: action.payload.email
      }
    }

    case 'setPalletInfo': {
      const { field, value } = action.payload
      const newForm = {
        ...state.receivablesFormCopy!,
        items: state.receivablesFormCopy!.items!.map((item) => {
          return {
            ...item,
            [field]: value
          }
        })
      }
      return {
        ...state,
        formChanged: !isEqual(state.receivablesForm?.items, newForm.items),
        receivablesFormCopy: newForm
      }
    }

    case 'setPhotos': {
      const newPhotos = action.payload.photos

      return {
        ...state,
        receivablesForm: {
          ...state.receivablesForm!,
          photos: newPhotos!
        },
        receivablesFormCopy: {
          ...state.receivablesFormCopy!,
          photos: newPhotos!
        }
      }
    }

    case 'setTableFields': {
      const { name, value, checked } = action.payload.tableField
      const [id, field] = name.split('-')
      const newForm = {
        ...state.receivablesFormCopy!,
        items: state.receivablesFormCopy!.items!.map((item) => {
          // Table field inputs
          if (item.ReceivableID === Number(id)) {
            if (field === 'ActualQty') {
              const newValue = Number(value)
              return {
                ...item,
                ActualQty: newValue,
                Storage:
                  item.Storage.length > 0
                    ? item.Storage.map((itemStorage) => {
                      return {
                        ...itemStorage,
                        Quantity: newValue
                      }
                    })
                    // eslint-disable-next-line operator-linebreak
                    : // Creates new storage object if one does not exist with default values (Location must be set to '')
                      ([
                      {
                        Quantity: newValue,
                        ItemID: item.ItemID,
                        ReceivableID: item.ReceivableID,
                        Location: ''
                      } as unknown
                      ] as Storage[])
              }
            } else if (field === 'Batch' || field === 'Expiry' || field === 'Location') {
              return {
                ...item,
                Storage:
                  item.Storage.length > 0
                    ? item.Storage.map((itemStorage) => {
                      return {
                        ...itemStorage,
                        [field]: value
                      }
                    })
                    // eslint-disable-next-line operator-linebreak
                    : // Handles if storage does not exist already (e.g. user enters value of Batch/Expiry/Location before ActualQty)
                      ([
                      {
                        Quantity: item.ActualQty ? item.ActualQty : 0,
                        ItemID: item.ItemID,
                        ReceivableID: item.ReceivableID,
                        Location: field === 'Location' ? value : ''
                      } as unknown
                      ] as Storage[])
              }
            } else if (field === 'Status') {
              const receivableOrderStatus = state.receivablesFormCopy?.order.Status || 1
              if (receivableOrderStatus === ReceivableStatus.AWAITING_STOCK || receivableOrderStatus === ReceivableStatus.COUNT_IN_PROGRESS) {
                const value = checked ? ReceivableStatus.PUTAWAY_IN_PROGRESS : ReceivableStatus.COUNT_IN_PROGRESS
                return {
                  ...item,
                  Status: value,
                  CountedDate: value === ReceivableStatus.PUTAWAY_IN_PROGRESS ? new Date().toISOString() : '',
                  CountedBy: value === ReceivableStatus.PUTAWAY_IN_PROGRESS ? state.userEmail : ''
                }
              } else if (receivableOrderStatus === ReceivableStatus.PUTAWAY_IN_PROGRESS || receivableOrderStatus === ReceivableStatus.APPROVED) {
                const value = checked ? ReceivableStatus.APPROVED : ReceivableStatus.PUTAWAY_IN_PROGRESS
                return {
                  ...item,
                  Status: value
                }
              } else {
                return {
                  ...item
                }
              }
            } else {
              return {
                ...item
              }
            }
          } else {
            return {
              ...item
            }
          }
        })
      }

      const isFormChanged = !isEqual(state.receivablesForm?.items, newForm.items)
      return {
        ...state,
        formChanged: isFormChanged,
        receivablesFormCopy: newForm
      }
    }

    case 'setReceivableItemExpiryDate': {
      const { receivableId, expiryDate } = action.payload
      if (!state.receivablesFormCopy) {
        return {
          ...state
        }
      }

      const itemCollection = state.receivablesFormCopy.items
      if (!itemCollection) {
        return {
          ...state
        }
      }

      const newItemCollection = [...itemCollection]
      const targetReceivableItemIndex = newItemCollection.findIndex(rec => rec.ReceivableID === receivableId)
      if (targetReceivableItemIndex < 0) {
        return {
          ...state
        }
      }

      const targetItem = newItemCollection[targetReceivableItemIndex]

      const storage: Storage = targetItem.Storage.length > 0
        ? targetItem.Storage[0]
        : {
            Quantity: targetItem.ActualQty ? targetItem.ActualQty : 0,
            ItemID: targetItem.ItemID,
            ReceivableID: receivableId,
            Location: ''
          }
      storage.Expiry = expiryDate ? expiryDate.toISOString() : null

      targetItem.Storage[0] = storage

      return {
        ...state,
        receivablesFormCopy: {
          ...state.receivablesFormCopy,
          items: newItemCollection
        }
      }
    }

    case 'addComment': {
      const newComment = action.payload.comment

      const newForm = {
        ...state.receivablesFormCopy!,
        comments: state.receivablesFormCopy!.comments!.concat(newComment)
      }

      return {
        ...state,
        receivablesForm: newForm,
        receivablesFormCopy: newForm
      }
    }

    case 'editComment': {
      const newComments = [...(state.receivablesFormCopy?.comments || [])]
      const targetCommentIndex = newComments.findIndex(c => c.ReceivableOrderCommentID === action.payload.receivableOrderCommentId)
      if (targetCommentIndex > -1) {
        newComments[targetCommentIndex] = {
          ReceivableOrderCommentID: action.payload.receivableOrderCommentId,
          Comment: action.payload.comment,
          CreatedTime: newComments[targetCommentIndex].CreatedTime // re-use the original created time
        }
        const newForm = {
          ...state.receivablesFormCopy!,
          comments: newComments
        }
        return {
          ...state,
          receivablesForm: newForm,
          receivablesFormCopy: newForm
        }
      }
      return {
        ...state
      }
    }

    case 'deleteComment': {
      const targetId = action.payload.receivableOrderCommentId
      const comments = [...(state.receivablesFormCopy?.comments || [])]
      const targetIndex = comments.findIndex(c => c.ReceivableOrderCommentID === targetId)
      if (targetIndex > -1) {
        comments.splice(targetIndex, 1)
        const newForm = {
          ...state.receivablesFormCopy!,
          comments
        }
        return {
          ...state,
          receivablesForm: newForm,
          receivablesFormCopy: newForm
        }
      }
      return {
        ...state
      }
    }

    case 'addHandlingTime': {
      const newHandlingTime = action.payload.inHandlingTime

      const newForm = {
        ...state.receivablesFormCopy!,
        handling: state.receivablesFormCopy!.handling!.concat(newHandlingTime)
      }

      return {
        ...state,
        receivablesForm: newForm,
        receivablesFormCopy: newForm
      }
    }

    case 'updateHandlingTime': {
      const updatedHandlingTime = action.payload.inHandlingTime
      const handlingTimes = [...(state.receivablesFormCopy?.handling || [])]
      const targetIndex = handlingTimes.findIndex(ht => ht.ReceivableOrderHandlingID === updatedHandlingTime.ReceivableOrderHandlingID)
      if (targetIndex > -1) {
        handlingTimes[targetIndex] = updatedHandlingTime
      }

      const newForm = {
        ...state.receivablesFormCopy!,
        handling: handlingTimes
      }

      return {
        ...state,
        receivablesForm: newForm,
        receivablesFormCopy: newForm
      }
    }

    case 'deleteHandlingTime': {
      const targetId = action.payload.handlingTimeId
      const handlingTimes = [...(state.receivablesFormCopy?.handling || [])]
      const targetIndex = handlingTimes.findIndex(ht => ht.ReceivableOrderHandlingID === targetId)
      if (targetIndex > -1) {
        handlingTimes.splice(targetIndex, 1)
      }

      const newForm = {
        ...state.receivablesFormCopy!,
        handling: handlingTimes
      }

      return {
        ...state,
        receivablesForm: newForm,
        receivablesFormCopy: newForm
      }
    }

    case 'setIsLoading': {
      return {
        ...state,
        isLoading: action.payload.value
      }
    }

    case 'setSnackbarNotification': {
      return {
        ...state,
        snackbarNotification: {
          message: action.payload.message,
          severity: action.payload.severity,
          open: action.payload.open
        }
      }
    }

    case 'setOpenEmailDraftDialog': {
      return {
        ...state,
        openEmailDraftDialog: action.payload.open
      }
    }

    case 'setShowEmailSentNotification': {
      return {
        ...state,
        showEmailSentNotification: action.payload.show
      }
    }

    case 'setSelectedReceivableID': {
      return {
        ...state,
        selectedReceivableID: action.payload.selectedReceivableID
      }
    }

    case 'setOpenNoteDialog': {
      return {
        ...state,
        openNoteDialog: action.payload.open
      }
    }

    case 'setCommentTextForReceivableId': {
      return {
        ...state,
        selectedReceivableIDForComment: action.payload.selectedReceivableID
      }
    }

    case 'setOpenTimeHandlingDialog': {
      return {
        ...state,
        openTimeHandlingDialog: action.payload.open
      }
    }

    case 'setOpenPrintDialog': {
      return {
        ...state,
        openPrintDialog: action.payload.open
      }
    }

    case 'setOpenFileSelectDialog': {
      return {
        ...state,
        openFileSelectDialog: action.payload.open
      }
    }

    case 'storeUnsavedForm': {
      localStorage.setItem('receivablesFormCopy', JSON.stringify(state.receivablesFormCopy))
      return {
        ...state
      }
    }

    case 'loadUnsavedForm': {
      const rawUnsavedForm = localStorage.getItem('receivablesFormCopy')
      const restoredReceivablesFormCopy = rawUnsavedForm ? JSON.parse(rawUnsavedForm) : undefined
      localStorage.removeItem('receivablesFormCopy')
      if (restoredReceivablesFormCopy) {
        return {
          ...state,
          receivablesFormCopy: restoredReceivablesFormCopy,
          formChanged: !isEqual(state.receivablesForm?.items, restoredReceivablesFormCopy.items),
          snackbarNotification: {
            message: 'Restoring unsaved form',
            severity: 'info',
            open: true
          }
        }
      }
      return {
        ...state
      }
    }

    default: {
      throw new Error(`Unhandled action type: ${action!}`)
    }
  }
}

export function ReceivablesFormProvider({ children, receivablesForm }: Props) {
  const initialState: State = {
    receivablesForm,
    receivablesFormCopy: receivablesForm,
    userEmail: undefined,
    isLoading: false,
    formChanged: false,
    snackbarNotification: defaultSnackbar,
    openEmailDraftDialog: false,
    showEmailSentNotification: false,
    selectedReceivableID: undefined,
    openNoteDialog: false,
    openTimeHandlingDialog: false,
    openPrintDialog: false,
    openFileSelectDialog: false
  }
  const [state, dispatch] = React.useReducer(receivablesFormReducer, initialState)
  return (
    <ReceivablesFormStateContext.Provider value={state}>
      <ReceivablesFormDispatchContext.Provider value={dispatch}>{children}</ReceivablesFormDispatchContext.Provider>
    </ReceivablesFormStateContext.Provider>
  )
}

export function useReceivablesFormState() {
  const context = React.useContext(ReceivablesFormStateContext)
  if (context === undefined) {
    throw new Error('useReceivablesFormState must be used within a TagProvider')
  }
  return context
}

export function useReceivablesFormDispatch() {
  const context = React.useContext(ReceivablesFormDispatchContext)
  if (context === undefined) {
    throw new Error('useReceivablesFormDispatch must be used within a TagProvider')
  }
  return context
}

export function setReceivablesForm(dispatch: Dispatch, receivablesForm?: ReceivableForm) {
  dispatch({ type: 'setReceivablesForm', payload: { receivablesForm } })
}

export function setUserEmail(dispatch: Dispatch, email: string) {
  dispatch({ type: 'setUserEmail', payload: { email } })
}

export function setPalletInfo(dispatch: Dispatch, field: string, value: any) {
  dispatch({ type: 'setPalletInfo', payload: { field, value } })
}

export function setPhotos(dispatch: Dispatch, photos: ImagePreview[]) {
  dispatch({ type: 'setPhotos', payload: { photos } })
}

export function setTableFields(dispatch: Dispatch, tableField: TableFieldProps) {
  dispatch({ type: 'setTableFields', payload: { tableField } })
}

export function addComment(dispatch: Dispatch, comment: Comments) {
  dispatch({ type: 'addComment', payload: { comment } })
}

export function editCommentProvider(dispatch: Dispatch, comment: {
  receivableOrderCommentId: number,
  comment: string
}) {
  dispatch({ type: 'editComment', payload: { ...comment } })
}

export function deleteCommentProvider(dispatch: Dispatch, receivableOrderCommentId: number) {
  dispatch({ type: 'deleteComment', payload: { receivableOrderCommentId } })
}

export function addHandlingTime(dispatch: Dispatch, inHandlingTime: HandlingTime) {
  dispatch({ type: 'addHandlingTime', payload: { inHandlingTime } })
}

export function updateHandlingTime(dispatch: Dispatch, inHandlingTime: HandlingTime) {
  dispatch({ type: 'updateHandlingTime', payload: { inHandlingTime } })
}

export function deleteHandlingTime(dispatch: Dispatch, handlingTimeId: number) {
  dispatch({ type: 'deleteHandlingTime', payload: { handlingTimeId } })
}

export function setIsLoading(dispatch: Dispatch, value: boolean) {
  dispatch({ type: 'setIsLoading', payload: { value } })
}

export function setSnackbarNotification(
  dispatch: Dispatch,
  open: boolean,
  severity?: 'error' | 'success' | 'info' | 'warning',
  message?: string
) {
  dispatch({ type: 'setSnackbarNotification', payload: { message, severity, open } })
}

export function setOpenEmailDraftDialog(dispatch: Dispatch, open: boolean) {
  dispatch({ type: 'setOpenEmailDraftDialog', payload: { open } })
}

export function setShowEmailSentNotification(dispatch: Dispatch, show: boolean) {
  dispatch({ type: 'setShowEmailSentNotification', payload: { show } })
}

export function setSelectedReceivableID(dispatch: Dispatch, selectedReceivableID: number | undefined) {
  dispatch({ type: 'setSelectedReceivableID', payload: { selectedReceivableID } })
}

export function setOpenNoteDialog(dispatch: Dispatch, open: boolean) {
  dispatch({ type: 'setOpenNoteDialog', payload: { open } })
}

export function setCommentTextForReceivableId(dispatch: Dispatch, selectedReceivableID: number) {
  dispatch({ type: 'setCommentTextForReceivableId', payload: { selectedReceivableID } })
}

export function setOpenTimeHandlingDialog(dispatch: Dispatch, open: boolean) {
  dispatch({ type: 'setOpenTimeHandlingDialog', payload: { open } })
}

export function setOpenPrintDialog(dispatch: Dispatch, open: boolean) {
  dispatch({ type: 'setOpenPrintDialog', payload: { open } })
}

export function setOpenFileSelectDialog(dispatch: Dispatch, open: boolean) {
  dispatch({ type: 'setOpenFileSelectDialog', payload: { open } })
}

export function storeUnsavedForm(dispatch: Dispatch) {
  dispatch({ type: 'storeUnsavedForm' })
}

export function loadUnsavedForm(dispatch: Dispatch) {
  dispatch({ type: 'loadUnsavedForm' })
}

export function setReceivableItemExpiryDate(dispatch: Dispatch, receivableId: number, expiryDate: Date | null) {
  dispatch({ type: 'setReceivableItemExpiryDate', payload: { receivableId, expiryDate } })
}
