import { IconButton, Menu, MenuItem, Chip, styled } from '@mui/material'
import { GridCellParams, GridColDef, GridComparatorFn, GridValueGetterParams } from '@mui/x-data-grid'
import { ExpandMore } from '@mui/icons-material'
import { Auth } from 'aws-amplify'
import React, { useState } from 'react'
import moment from 'moment'
import { redirectToLogin } from '../../../aws-exports'
import { GetReceivableOrders } from '../../../graphQL/ReceivableOrder'
import { UpdateReceivableOrderStatus } from '../../../graphQL/ReceivablesOrderStatus'
import {
  resetReceivableOrders,
  setIsLoading,
  setReceivableOrders,
  useReceivableOrderDispatch
} from '../../../providers/ReceivableOrdersProvider'

import { setUserEmail, useReceivablesFormDispatch, useReceivablesFormState } from '../../../providers/ReceivablesFormProvider'
import { DATE_STATE, wamDateStringFormat } from '../../../utils'
import { ReceivableStatus } from '../../../models/ReceivableStatus'
import { colors as qmColors } from '../../../theme/colorPalette'

const StyledMenuItem = styled(MenuItem)(() => ({
  color: 'white'
}))

function Columns(index: Number) {
  const dispatch = useReceivableOrderDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [updateStatusId, setUpdateStatusId] = useState<number>()
  const formDispatch = useReceivablesFormDispatch()
  const { userEmail } = useReceivablesFormState()

  React.useEffect(() => {
    const setCurrentUserEmail = async () => {
      try {
        const { attributes }: { attributes: { email: string } } = await Auth.currentUserInfo()
        setUserEmail(formDispatch, attributes.email)
      } catch (error) {
        console.log("User's session may have expired", error)
        redirectToLogin()
      }
    }
    setCurrentUserEmail()
  }, [formDispatch, userEmail])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: number) => {
    event.stopPropagation()
    setUpdateStatusId(id)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setUpdateStatusId(undefined)
    setAnchorEl(null)
  }

  const statusChangeHandler = async (status: ReceivableStatus) => {
    handleClose()
    resetReceivableOrders(dispatch)
    if (updateStatusId && userEmail) {
      setIsLoading(dispatch, true)
      await UpdateReceivableOrderStatus(updateStatusId, status, userEmail)
    }
    async function fetchReceivableOrders() {
      const receivableOrders = await GetReceivableOrders()
      setReceivableOrders(dispatch, receivableOrders)
      setIsLoading(dispatch, false)
    }
    fetchReceivableOrders()
  }

  const statusColumn = {
    field: 'status',
    headerName: 'Status',
    flex: 1.2,
    cellClassName: 'cellPaddingLeft',
    disableClickEventBubbling: true,
    renderCell: (params: GridCellParams) => (
      <>
        {params.value}
        <IconButton
          sx={{
            marginLeft: '5px',
            zIndex: 2
          }}
          aria-label="status"
          aria-controls={`status-menu-${params.value}`}
          aria-haspopup="true"
          onClick={(event) => handleClick(event, params.row.id)}
          data-testid={`row-${params.row.id}-dropdown`}
          key={params.row.id}
          size="large"
        >
          <ExpandMore fontSize="small" />
        </IconButton>
        <Menu
          id={`status-menu-${params.value}`}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          anchorEl={anchorEl}
          sx={(theme) => ({
            '& .MuiPaper-root': {
              backgroundColor: theme.palette.primary.main
            }
          })}
        >
          <StyledMenuItem data-testid={`awaitstock-${params.row.id}`} onClick={() => statusChangeHandler(ReceivableStatus.AWAITING_STOCK)}>
            Await Stock
          </StyledMenuItem>
          <StyledMenuItem
            data-testid={`countinprogreess-${params.row.id}`}
            onClick={() => statusChangeHandler(ReceivableStatus.COUNT_IN_PROGRESS)}
          >
            Count In Progress
          </StyledMenuItem>
          <StyledMenuItem
            data-testid={`putaway-${params.row.id}`}
            onClick={() => statusChangeHandler(ReceivableStatus.PUTAWAY_IN_PROGRESS)}
          >
            Putaway
          </StyledMenuItem>
          <StyledMenuItem data-testid={`approved-${params.row.id}`} onClick={() => statusChangeHandler(ReceivableStatus.APPROVED)}>
            Approved
          </StyledMenuItem>
        </Menu>
      </>
    )
  }

  const columns: GridColDef[] = [
    { field: 'client', headerName: 'Client', flex: 1, cellClassName: `cellStyle${index}` },
    {
      field: 'id',
      headerName: 'Rec #',
      flex: 0.7,
      cellClassName: 'cellPaddingLeft',
      headerAlign: 'right',
      align: 'right'
    },
    {
      field: 'reference',
      headerName: 'Ref #',
      flex: 0.7,
      cellClassName: 'cellPaddingLeft',
      headerAlign: 'right',
      align: 'right'
    },
    {
      field: 'supplier',
      headerName: 'Supplier',
      flex: 0.9,
      cellClassName: 'cellPaddingLeft',
      headerAlign: 'left',
      align: 'left'
    },
    {
      field: 'siteId',
      headerName: 'Site',
      flex: 0.6,
      cellClassName: 'cellPaddingLeft',
      headerAlign: 'left',
      align: 'left'
    }
  ]

  const valueGetter = (params: GridValueGetterParams) => {
    const val = params.value as string
    return wamDateStringFormat(val)
  }

  const sortComparator: GridComparatorFn = (v1, v2) => {
    const date1Val = v1 as string
    const date2Val = v2 as string
    const date1 = date1Val.toLowerCase() === 'n/a' ? 0 : moment(date1Val, 'DD/MM/YYYY').unix()
    const date2 = date2Val.toLowerCase() === 'n/a' ? 0 : moment(date2Val, 'DD/MM/YYYY').unix()

    return date1 - date2
  }

  const expectedDateColumn: GridColDef = {
    field: 'expectedDate',
    headerName: 'Expected',
    flex: 1,
    minWidth: 100,
    cellClassName: 'cellPaddingLeft',
    headerAlign: 'right',
    align: 'right',
    valueGetter,
    sortComparator
  }

  const receivedDateColumn: GridColDef = {
    field: 'receivedDate',
    headerName: 'Received',
    flex: 1,
    minWidth: 100,
    cellClassName: 'cellPaddingLeft',
    headerAlign: 'right',
    align: 'right',
    valueGetter,
    sortComparator
  }

  const countedDateColumn: GridColDef = {
    field: 'countedDate',
    headerName: 'Approved',
    flex: 1,
    minWidth: 100,
    cellClassName: 'cellPaddingLeft',
    headerAlign: 'right',
    align: 'right',
    valueGetter,
    sortComparator
  }

  const containerSizeColumn: GridColDef = {
    field: 'containerSize',
    headerName: 'Container',
    flex: 0.8,
    cellClassName: 'cellPaddingLeft',
    headerAlign: 'left',
    align: 'left',
    renderCell: (cellValues) => {
      return Number(cellValues.value) === 0
        ? (
        <>
          <div style={{}}>{'N/A'}</div>
        </>
          )
        : (
        <>
          <div style={{}}>{cellValues.value + 'ft'}</div>
        </>
          )
    }
  }

  const SHOW_INDICATOR = process.env.REACT_APP_EXPECTED_DATE_INDICATOR === 'true'
  const overdueColumn: GridColDef = {
    field: 'overdueDateExpectedDate',
    headerName: ' ',
    flex: 1,
    width: 75,
    cellClassName: 'cellPaddingLeft',
    headerAlign: 'right',
    align: 'left',
    sortable: false,
    renderCell: (params) => {
      const val = params.value
      return (
        <>
          {val === DATE_STATE.PAST && (
            <Chip
              label="overdue"
              sx={{
                backgroundColor: qmColors.qmRed,
                color: 'white',
                marginRight: '5px',
                borderRadius: '4px'
              }}
            />
          )}
          {val === DATE_STATE.TODAY && (
            <Chip
              label="today"
              sx={{
                backgroundColor: qmColors.qmOrange,
                color: 'white',
                marginRight: '5px',
                borderRadius: '4px'
              }}
            />
          )}
        </>
      )
    }
  }

  let updatedColumns: GridColDef[] = []
  if (index === 0) {
    // Await Stock tab
    updatedColumns = [...columns, expectedDateColumn, containerSizeColumn, statusColumn]
    if (SHOW_INDICATOR) {
      updatedColumns.push(overdueColumn)
    }
  } else if (index === 1) {
    // Count in progress tab
    updatedColumns = [...columns, receivedDateColumn, containerSizeColumn, statusColumn]
  } else {
    updatedColumns = [...columns, countedDateColumn, containerSizeColumn, statusColumn]
  }

  return updatedColumns
}

export default Columns
