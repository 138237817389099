import { API, graphqlOperation } from 'aws-amplify'

interface PhotoInput {
  Path: String
  UploadedDate: String
  FileName: String
}

const removePhotoMutation = `mutation deletePhoto($photoId: Int!) {
    deletePhoto(photoId: $photoId)
}`

const attachPhotoMutation = `mutation attachPhoto($receivableOrderID: Int!, $photo: PhotoInput!){
    attachPhoto(receivableOrderID: $receivableOrderID, photo: $photo)
}`

interface AttachPhotoResponse {
  data: {
    attachPhoto: number
  }
}

export const removePhoto = async(photoId: number) => {
  try {
    await API.graphql(
      graphqlOperation(removePhotoMutation, {
        photoId
      })
    )
  } catch (e) {
    console.log(e) // TODO - Add notification to errors
  }
}

export const attachPhoto = async(receivableOrderID: number, photo: PhotoInput): Promise<number | undefined> => {
  try {
    const attachResponse = (await API.graphql(
      graphqlOperation(attachPhotoMutation, {
        receivableOrderID,
        photo
      })
    )) as AttachPhotoResponse
    return attachResponse.data.attachPhoto
  } catch (e) {
    console.log(e) // TODO - Add notification to errors
  }
}
