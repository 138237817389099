import { API, graphqlOperation } from 'aws-amplify'

export interface Comments {
  ReceivableOrderCommentID: number
  CreatedTime: string
  Comment: string
}

interface CommentUpdatedMutationResult {
  data: {
    updateComments: Comments
  }
}

const updateCommentsMutation = `mutation updateComments($receivableOrderID: Int!, $comments: Comments){
  updateComments(receivableOrderID: $receivableOrderID, comments: $comments) {
    ReceivableOrderCommentID
    Comment
    CreatedTime
  }
}`

export const submitComments = async (id: number, comments: string): Promise<Comments> => {
  let newComment: Comments = {} as Comments
  try {
    const commentUpdateResult = (await API.graphql(
      graphqlOperation(updateCommentsMutation, {
        receivableOrderID: id,
        comments: {
          CreatedTime: new Date(),
          Comment: comments
        }
      })
    )) as CommentUpdatedMutationResult
    if (commentUpdateResult.data.updateComments) newComment = commentUpdateResult.data.updateComments
  } catch (e) {
    console.log(e) // TODO - Add notification to errors
  }
  return newComment
}

interface EditCommentResult {
  data: {
    editComment: Comments | null
  }
}

const editCommentMutation = `mutation EditComment($receivableOrderCommentID: Int!, $comments: Comments){
  editComment(receivableOrderCommentID: $receivableOrderCommentID, comments: $comments) {
    ReceivableOrderCommentID
    Comment
    CreatedTime
  }
}`

export const editComment = async (id: number, comments: string): Promise<Comments | undefined> => {
  try {
    const commentUpdateResult = (await API.graphql(
      graphqlOperation(editCommentMutation, {
        receivableOrderCommentID: id,
        comments: {
          CreatedTime: new Date(),
          Comment: comments
        }
      })
    )) as EditCommentResult
    return commentUpdateResult.data.editComment || undefined
  } catch (e) {
    console.log(e) // TODO - Add notification to errors
  }
}

interface DeleteCommentResult {
  data: {
    deleteComment: boolean
  }
}

const deleteCommentMutation = `mutation DeleteComment($receivableOrderCommentID: Int!){
  deleteComment(receivableOrderCommentID: $receivableOrderCommentID)
}`

export const deleteComment = async (id: number): Promise<boolean> => {
  try {
    const deleteCommentResult = (await API.graphql(
      graphqlOperation(deleteCommentMutation, {
        receivableOrderCommentID: id
      })
    )) as DeleteCommentResult
    return deleteCommentResult.data.deleteComment
  } catch (e) {
    console.log(e) // TODO - Add notification to errors
  }
  return false
}
