import { Box } from '@mui/material'
import { Close } from '@mui/icons-material'
import React from 'react'

interface PhotoProps {
  receivableOrderPhotoID: number
  url: string
  fileName: string
  onImageClicked: (photoId: number) => void
  onImageRemoveClicked: (photoId: number) => void
}

const PhotoPreview: React.FC<PhotoProps> = ({ onImageClicked, onImageRemoveClicked, receivableOrderPhotoID, url, fileName }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        cursor: 'pointer'
      }}
    >
      <Box
        component="img"
        borderRadius="5px"
        marginRight={2}
        my={1}
        width={100}
        height={100}
        src={url}
        alt="Attached"
        title={fileName}
        onClick={() => onImageClicked(receivableOrderPhotoID)}
      />
      <Close
        sx={(theme) => ({
          top: 10,
          right: 18,
          color: 'white',
          position: 'absolute',
          backgroundColor: theme.palette.text.primary,
          borderRadius: '5px'
        })}
        fontSize="small"
        onClick={() => onImageRemoveClicked(receivableOrderPhotoID)}
      />
    </Box>
  )
}

export default PhotoPreview
