import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow
} from '@mui/material'
import React from 'react'
import { ReceivableStatus } from '../../../models/ReceivableStatus'
import {
  useReceivablesFormState
} from '../../../providers/ReceivablesFormProvider'
import { colors as qmColors } from '../../../theme/colorPalette'
import FilterTable from './FilterTable'
import ReceivableItemRow from './ReceivableItemRow'

export default function FormTable() {
  const { receivablesFormCopy } = useReceivablesFormState()
  const [receivableItemIds, setReceivabeItemIds] = React.useState<number[]>([])
  const [filterKeyword, setFilterKeyword] = React.useState<string>('')
  const receivableOrderStatus = receivablesFormCopy?.order.Status || 1
  const putAwayInProgress = receivableOrderStatus === ReceivableStatus.PUTAWAY_IN_PROGRESS
  const approved = receivableOrderStatus === ReceivableStatus.APPROVED

  const onFilterChange = (keyword: string) => setFilterKeyword(keyword)
  const onFilterCancelSearch = () => setFilterKeyword('')

  React.useEffect(() => {
    if (receivablesFormCopy?.items) {
      const allItems = receivablesFormCopy.items
      if (filterKeyword !== '') {
        const lowercaseKeyword = filterKeyword.toLowerCase()
        const filteredItems = allItems.filter(item => item.ItemName.toLowerCase().includes(lowercaseKeyword) || item.CustItemID.toLowerCase().includes(lowercaseKeyword))
        if (JSON.stringify(receivableItemIds) !== JSON.stringify(filteredItems.map(rec => rec.ReceivableID))) {
          setReceivabeItemIds(filteredItems.map(rec => rec.ReceivableID))
        }
      } else {
        if (JSON.stringify(receivableItemIds) !== JSON.stringify(allItems.map(rec => rec.ReceivableID))) {
          setReceivabeItemIds(allItems.map(rec => rec.ReceivableID))
        }
      }
    }
  }, [receivablesFormCopy?.items, filterKeyword, receivableItemIds])

  return React.useMemo(() => (
    <>
      {receivablesFormCopy! && (
        <>
          <FilterTable keyword={filterKeyword} onChange={onFilterChange} onCancelSearch={onFilterCancelSearch} />
          <TableContainer sx={{
            marginTop: '10px',
            maxHeight: 800,
            paddingBottom: '0px',
            marginBottom: '16px',
            backgroundColor: '#FFFFFF'
          }}>
            <Table stickyHeader>
              <TableHead sx={(theme) => ({
                '& th': {
                  backgroundColor: qmColors.qmBlue,
                  color: '#FFFFFF',
                  fontWeight: 600,
                  [theme.breakpoints.down('lg')]: {
                    fontSize: '0.9rem',
                    padding: '1em'
                  },
                  [theme.breakpoints.down('md')]: {
                    fontSize: '0.72rem',
                    padding: '1em'
                  }
                }
              })}>
                <TableRow>
                  <TableCell align="left" size="small">
                    SKU
                  </TableCell>
                  <TableCell align="left" size="medium">
                    Product
                  </TableCell>
                  <TableCell align="right" size="small">
                    Expected Qty
                  </TableCell>
                  <TableCell align="right" size="small">
                    Actual Qty
                  </TableCell>
                  <TableCell align="right" size="small">
                    Batch No.
                  </TableCell>
                  <TableCell align="left" size="small">
                    Expiry
                  </TableCell>
                  {(putAwayInProgress || approved) && (
                    <TableCell align="left" size="small">
                      Location
                    </TableCell>
                  )}
                  <TableCell align="center" size="small">
                    {(putAwayInProgress || approved)
                      ? <>Putaway</>
                      : <>Counted</>
                    }
                  </TableCell>
                  <TableCell align="center" size="small">
                    <>&nbsp;</>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {receivableItemIds.map((receivableId) => (
                  <ReceivableItemRow receivableId={receivableId} key={receivableId} />
                ))}
              </TableBody>
              <TableFooter>
                <TableRow sx={(theme) => ({
                  height: '40px',
                  [theme.breakpoints.down('md')]: {
                    height: '20px'
                  }
                })} />
              </TableFooter>
            </Table>
          </TableContainer>
        </>
      )
      }
    </>), [receivablesFormCopy, filterKeyword, putAwayInProgress, approved, receivableItemIds]
  )
}
