import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import { Close, PhotoCameraOutlined } from '@mui/icons-material'
import { Storage } from 'aws-amplify'
import React, { useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Photo } from '../../../graphQL/ReceivablesPhotos'
import { attachPhoto } from './photosHelper'
import { colors as qmColors } from '../../../theme/colorPalette'
import { customNowDateString } from '../../../utils'
import { setOpenFileSelectDialog, useReceivablesFormDispatch, useReceivablesFormState } from '../../../providers/ReceivablesFormProvider'

interface AddPhotosProps {
  receivableOrderID: number
  onPhotoAdded: (photo: Photo) => void
}

const AddPhotos: React.FC<AddPhotosProps> = ({ receivableOrderID, onPhotoAdded }) => {
  const { openFileSelectDialog, userEmail, selectedReceivableID, receivablesForm } = useReceivablesFormState()
  const dispatch = useReceivablesFormDispatch()
  const selectedReceivableItem = receivablesForm?.items.find((rec) => rec.ReceivableID === selectedReceivableID)
  const initialDescription = selectedReceivableItem ? `${selectedReceivableItem.CustItemID}\n${selectedReceivableItem.ItemName}\n` : ''
  const [openAddPhotoDialog, setOpenAddPhotoDialog] = useState(false)
  const [fileToUpload, setFileToUpload] = useState<File | undefined>()
  const [fileIsUploading, setFileIsUploading] = useState<boolean>(false)
  const [fileName, setFileName] = useState(initialDescription)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [imgSrc, setImgSrc] = useState('')
  const fileUploaderRef = useRef<HTMLInputElement>(null)

  const onUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFileToUpload(e.target.files[0])
      setOpenAddPhotoDialog(true)
      setOpenFileSelectDialog(dispatch, false)
      setImgSrc(URL.createObjectURL(e.target.files[0]))
    }
  }

  const resetFileInput = (event: React.MouseEvent<HTMLInputElement>) => {
    const element = event.target as HTMLInputElement
    element.value = ''
  }

  React.useEffect(() => {
    if (openFileSelectDialog && fileUploaderRef.current) {
      fileUploaderRef.current.click()
    }
  }, [openFileSelectDialog])

  React.useEffect(() => {
    setFileName(initialDescription)
  }, [initialDescription])

  const onUploadClicked = async () => {
    setFileIsUploading(true)
    setUploadProgress(0)
    const path = uuid()
    try {
      const storageLocation = (await Storage.put(path, fileToUpload, {
        progressCallback(progress: { loaded: number; total: number }) {
          setUploadProgress((progress.loaded / progress.total) * 100)
        }
      })) as { key: string }
      const photoId = await attachPhoto(receivableOrderID, {
        FileName: fileName,
        Path: path,
        UploadedDate: new Date().toISOString()
      })
      if (photoId && storageLocation) {
        onPhotoAdded({
          FileName: fileName,
          Path: storageLocation.key,
          ReceivableOrderID: receivableOrderID,
          ReceivableOrderPhotoID: photoId,
          UploadedBy: '',
          UploadedDate: new Date().toISOString()
        })
      }
    } catch (e) {
      console.log(e) // TODO - Add notification to errors
    }
    setFileIsUploading(false)
    setFileToUpload(undefined)
    setFileName('')
    setOpenAddPhotoDialog(false)
  }

  const onFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setFileName(e.target.value)

  const onCancel = () => {
    setOpenAddPhotoDialog(false)
    setOpenFileSelectDialog(dispatch, false)
    setFileToUpload(undefined)
  }

  const DialogTitle = (props: any) => {
    const { children, ...other } = props
    return (
      <MuiDialogTitle {...other} sx={{ backgroundColor: qmColors.qmBlue60, color: 'white', marginBottom: '10px' }}>
        <Typography color="#FFFFFF" fontSize="18px" fontWeight={600}>
          {children}
        </Typography>
        <IconButton
          aria-label="close"
          sx={(theme) => ({
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: '#FFFFFF'
          })}
          onClick={onCancel}
          size="large"
        >
          <Close />
        </IconButton>
      </MuiDialogTitle>
    )
  }

  return (
    <>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="icon-button-file"
        type="file"
        onChange={onUploadChange}
        capture="environment"
        onClick={resetFileInput}
        ref={fileUploaderRef}
      />
      <label htmlFor="icon-button-file">
        <Box
          sx={{
            backgroundColor: 'rgb(196, 196, 196, 0.2)',
            cursor: 'pointer'
          }}
          marginRight={2}
          my={1}
          width={100}
          height={100}
          alignItems="center"
          display="flex"
          justifyContent="center"
          borderRadius="5px"
        >
          <PhotoCameraOutlined />
        </Box>
      </label>
      <Dialog maxWidth="lg" scroll="paper" open={openAddPhotoDialog}>
        <DialogTitle>Image Details</DialogTitle>
        <DialogContent
          sx={{
            padding: '8px 15px !important',
            display: 'flex',
            justifyContent: 'space-between',
            gap: '15px'
          }}
        >
          <div>
            <img id="selected-image" src={imgSrc} style={{ maxWidth: '525px' }} alt="selected" />
          </div>
          <div>
            <TextField
              variant="outlined"
              disabled={fileIsUploading}
              fullWidth
              label="Give this photo a name or description"
              onChange={onFileNameChange}
              InputLabelProps={{ shrink: true, disabled: false }}
              style={{ minWidth: '400px', marginBottom: '15px' }}
              multiline={true}
              minRows={5}
              value={fileName}
            />
            <div style={{ marginBottom: '15px' }}>
              <Typography fontWeight={600} fontSize="14px" lineHeight="21px">
                Date &amp; Time
              </Typography>
              <Typography fontWeight={400} fontSize="14px" lineHeight="17px" color={qmColors.grey50}>
                {customNowDateString('DD/MM/YYYY HH:mmA')}
              </Typography>
            </div>
            <div style={{ marginBottom: '15px' }}>
              <Typography fontWeight={600} fontSize="14px" lineHeight="21px">
                Uploaded By
              </Typography>
              <Typography fontWeight={400} fontSize="14px" lineHeight="17px" color={qmColors.grey50}>
                {userEmail}
              </Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ marginRight: '5px' }}>
          <Button onClick={onCancel} variant="outlined" disabled={fileIsUploading}>
            Cancel
          </Button>
          <Button onClick={onUploadClicked} variant="contained" disabled={fileIsUploading || fileName.length === 0 || !fileToUpload}>
            Upload
          </Button>
          {fileIsUploading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%'
              }}
              variant="determinate"
              value={uploadProgress}
            />
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddPhotos
