import { FC } from 'react'
import SearchBar from '../../../components/SearchBar'

interface Props {
  keyword: string
  onChange: (keyword: string) => void
  onCancelSearch: () => void
}

const FilterTable: FC<Props> = ({ keyword, onChange, onCancelSearch }) => {
  return (
    <SearchBar
      value={keyword}
      onChange={onChange}
      onCancelSearch={onCancelSearch}
      placeholder="Filter by keyword"
      data-testid="search-bar"
      sx={(theme) => ({
        height: theme.spacing(5),
        maxWidth: '351px',
        marginLeft: 'auto',
        marginRight: '0'
      })}
    />
  )
}

export default FilterTable
