import { PaletteOptions } from '@mui/material/styles'

declare type Colour = {
  [color: string]: string
}

export const colors: Colour = {
  darkGrey: '#5F5F5F',
  lightGrey: '#E8E5E2',
  midGrey: '#333132',
  grey30: '#EDEDED',
  grey40: '#D6D6D6',
  grey50: '#BCBCBC',
  // qmBlue: '#003B75',
  qmBlue: '#004990',
  qmBlue05: '#F2F6F9',
  qmBlue30: '#B2C8DD',
  qmBlue60: '#6692BC',
  blue: '#6692BC',
  brightBlue: '#006BDF',
  infoBlue: '#2384F2',
  green: '#47A16D',
  darkGreen: '#056B2E',
  successGreen: '#00AB40',
  qmRed: '#EE2E24',
  errorRed: '#E1001B',
  paleRed: '#FFCCCB',
  teal: '#33C0CD',
  qmOrange: '#F56802'
}

export const palette: PaletteOptions = {
  primary: {
    main: colors.qmBlue,
    light: colors.brightBlue
  },
  error: {
    main: colors.qmRed
  },
  success: {
    main: colors.green,
    dark: colors.darkGreen
  },
  text: {
    primary: colors.darkGrey
  },
  grey: {
    50: '#EDEDED',
    100: '#BCBCBC'
  },
  warning: {
    main: colors.qmOrange
  }
}
