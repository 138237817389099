import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, Typography } from '@mui/material'
import { ExpandMore, CommentOutlined } from '@mui/icons-material'
import { FC, useEffect, useState } from 'react'
import AddButton from '../../../components/Buttons/AddButton'
import { useReceivablesFormDispatch, useReceivablesFormState, setOpenNoteDialog } from '../../../providers/ReceivablesFormProvider'
import { wamDateStringFormat } from '../../../utils'
import EditComment from './EditComment'
import DeleteComment from './DeleteComment'
import NoteTextbox from './NoteTextbox'

export const HeaderAddNoteButton: FC = () => {
  const dispatch = useReceivablesFormDispatch()
  const onAddNoteButtonClick = () => setOpenNoteDialog(dispatch, true)
  return (
    <IconButton style={{ color: '#FFFFFF' }} onClick={onAddNoteButtonClick} size="large">
      <CommentOutlined />
    </IconButton>
  )
}

const Comments: FC = () => {
  const { receivablesFormCopy, openNoteDialog } = useReceivablesFormState()
  const dispatch = useReceivablesFormDispatch()
  const [comments, setComments] = useState(receivablesFormCopy?.comments || [])

  useEffect(() => {
    setComments(receivablesFormCopy?.comments || [])
  }, [receivablesFormCopy?.comments])

  const onAddNoteButtonClick = () => setOpenNoteDialog(dispatch, true)

  return (
    <Grid item xs={6}>
      <Box border="1px solid #bdbdbd" borderRadius="5px" padding="0 10px 10px 10px">
        <Accordion
          defaultExpanded
          sx={(theme) => ({
            padding: '0px',
            minHeight: theme.typography.pxToRem(60),
            boxShadow: 'none'
          })}
        >
          <AccordionSummary expandIcon={<ExpandMore />} sx={{
            minHeight: '48px',
            maxHeight: '48px'
          }}>
            <Typography fontSize="14px" lineHeight="21px" fontWeight={700}>
              Notes to Client
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <NoteTextbox />
            {comments.map((comment) => (
              <Grid container justifyContent="space-between" data-testid={`comment-${comment.ReceivableOrderCommentID}`} key={comment.ReceivableOrderCommentID}>
                <Grid item display="flex" alignItems="center">
                  <Grid
                    item
                    sx={(theme) => ({
                      paddingRight: '8px',
                      color: theme.palette.grey[100]
                    })}
                  >
                    {wamDateStringFormat(comment.CreatedTime)}
                  </Grid>
                  <Grid item>
                    {comment.Comment.split('\n').map((item, index) => {
                      return (
                        <span key={index}>
                          {item}
                          <br />
                        </span>
                      )
                    })}
                  </Grid>
                </Grid>
                <Grid item gap="15px" display="flex">
                  <EditComment receivableOrderCommentId={comment.ReceivableOrderCommentID} currentComment={comment.Comment} />
                  <DeleteComment receivableOrderCommentId={comment.ReceivableOrderCommentID} />
                </Grid>
              </Grid>
            ))}
          </AccordionDetails>
        </Accordion>
        <Box padding='0px 16px'>
          <AddButton onClick={onAddNoteButtonClick} buttonText="Add Note" disabled={openNoteDialog} data-testid="button-notes-to-client" />
        </Box>
      </Box>
    </Grid>
  )
}

export default Comments
