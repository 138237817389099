import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  CircularProgress,
  styled
} from '@mui/material'
import { Close, DeleteOutlined } from '@mui/icons-material'
import React from 'react'
import { deleteComment } from '../../../graphQL/ReceivablesComments'
import {
  deleteCommentProvider,
  setSnackbarNotification,
  useReceivablesFormDispatch,
  useReceivablesFormState
} from '../../../providers/ReceivablesFormProvider'
import { colors as qmColors } from '../../../theme/colorPalette'

interface Props {
  receivableOrderCommentId: number
}

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: '#FFFFFF'
}))

export default function DeleteComment({ receivableOrderCommentId }: Props) {
  const dispatch = useReceivablesFormDispatch()
  const { openNoteDialog } = useReceivablesFormState()
  const [isLoading, setIsLoading] = React.useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)

  const closeDialog = React.useCallback(() => {
    setOpenDialog(false)
  }, [])

  const onConfirm = React.useCallback(async () => {
    try {
      setIsLoading(true)
      await deleteComment(receivableOrderCommentId)
      deleteCommentProvider(dispatch, receivableOrderCommentId)
      setIsLoading(false)
      setSnackbarNotification(dispatch, true, 'success', 'Notes to client deleted')
    } catch (e) {
      setSnackbarNotification(dispatch, true, 'error', 'Error deleting notes to client')
    }
    closeDialog()
  }, [dispatch, receivableOrderCommentId, closeDialog])

  const DialogTitle = React.useCallback(
    (props: any) => {
      const { children, ...other } = props
      return (
        <MuiDialogTitle sx={{ background: qmColors.qmBlue60, marginBottom: '10px' }} {...other}>
          <Typography sx={{ color: '#FFFFFF', fontSize: '18px', fontWeight: 600 }}>{children}</Typography>
          <StyledCloseButton aria-label="close" onClick={closeDialog} size="large">
            <Close />
          </StyledCloseButton>
        </MuiDialogTitle>
      )
    },
    [closeDialog]
  )

  return React.useMemo(
    () => (
      <>
        <IconButton
          onClick={() => setOpenDialog(true)}
          disabled={openNoteDialog}
          sx={{ color: qmColors.green }}
          data-testid={`delete-comment-button-${receivableOrderCommentId}`}
          size="large"
        >
          <DeleteOutlined />
        </IconButton>
        <Dialog maxWidth="lg" scroll="paper" open={openDialog} onClose={closeDialog}>
          <DialogTitle>Delete Comments</DialogTitle>
          <DialogContent sx={{ minWidth: '300px', padding: '8px 15px !important', display: 'flex', justifyContent: 'center' }}>
            {isLoading && <CircularProgress />}
            {!isLoading && <>Are you sure you would like to delete this notes to client?</>}
          </DialogContent>
          <DialogActions sx={{ margin: '0 7px' }}>
            {!isLoading && (
              <>
                <Button onClick={closeDialog} variant="outlined" data-testid="delete-comment-cancel-button">
                  Cancel
                </Button>
                <Button onClick={onConfirm} variant="contained" data-testid="delete-comment-confirm-button">
                  Confirm
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </>
    ),
    [openDialog, openNoteDialog, DialogTitle, receivableOrderCommentId, isLoading, onConfirm, closeDialog]
  )
}
