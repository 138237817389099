import { API, graphqlOperation } from 'aws-amplify'

export interface HandlingTime {
  ReceivableOrderHandlingID: number
  HandlingDate: string
  HandlingTime: number
}

interface HandlingTimeAddMutationResult {
  data: {
    addHandlingTime: HandlingTime
  }
}

const addHandlingTimeMutation = `mutation addHandlingTime($receivableOrderID: Int, $inHandlingEntry: HandlingEntry){
  addHandlingTime(receivableOrderID: $receivableOrderID, inHandlingEntry: $inHandlingEntry) {
    ReceivableOrderHandlingID
    HandlingDate
    HandlingTime
  }
}`

export const submitHandlingTime = async (inReceivableOrderID: number, inHandlingTime?: number): Promise<HandlingTime> => {
  let newHandlingTime: HandlingTime = {} as HandlingTime
  try {
    const HandlingTimeUpdateResult = (await API.graphql(
      graphqlOperation(addHandlingTimeMutation, {
        receivableOrderID: inReceivableOrderID,
        inHandlingEntry: {
          HandlingDate: new Date(),
          HandlingTime: inHandlingTime
        }
      })
    )) as HandlingTimeAddMutationResult
    if (HandlingTimeUpdateResult.data.addHandlingTime) newHandlingTime = HandlingTimeUpdateResult.data.addHandlingTime
  } catch (e) {
    console.log(e) // TODO - Add notification to errors
  }
  return newHandlingTime
}

interface HandlingTimeUpdateMutationResult {
  data: {
    updateHandlingTime: HandlingTime
  }
}

const updateHandlingTimeMutation = `mutation updateHandlingTime($receivableOrderHandlingID: Int, $inHandlingEntry: HandlingEntry){
  updateHandlingTime(receivableOrderHandlingID: $receivableOrderHandlingID, inHandlingEntry: $inHandlingEntry) {
    ReceivableOrderHandlingID
    HandlingDate
    HandlingTime
  }
}`

export const submitUpdateHandlingTime = async (receivableOrderHandlingID: number, inHandlingTime?: number): Promise<HandlingTime> => {
  let newHandlingTime: HandlingTime = {} as HandlingTime
  try {
    const result = (await API.graphql(
      graphqlOperation(updateHandlingTimeMutation, {
        receivableOrderHandlingID,
        inHandlingEntry: {
          HandlingDate: new Date(),
          HandlingTime: inHandlingTime
        }
      })
    )) as HandlingTimeUpdateMutationResult
    if (result.data.updateHandlingTime) {
      newHandlingTime = result.data.updateHandlingTime
    }
  } catch (e) {
    console.log(e) // TODO - Add notification to errors
  }
  return newHandlingTime
}

interface HandlingTimeDeleteMutationResult {
  data: {
    deleteHandlingTime: boolean
  }
}

const deleteHandlingTimeMutation = `mutation deleteHandlingTime($receivableOrderHandlingID: Int){
  deleteHandlingTime(receivableOrderHandlingID: $receivableOrderHandlingID)
}`

export const submitDeleteHandlingTime = async (receivableOrderHandlingID: number): Promise<boolean> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(deleteHandlingTimeMutation, {
        receivableOrderHandlingID
      })
    )) as HandlingTimeDeleteMutationResult
    if (result.data.deleteHandlingTime) {
      return result.data.deleteHandlingTime
    }
  } catch (e) {
    console.log(e) // TODO - Add notification to errors
  }
  return false
}
