import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React from 'react'
import { setPalletInfo, useReceivablesFormDispatch, useReceivablesFormState } from '../../../providers/ReceivablesFormProvider'

const UNIT_MODEL: {
  key: number
  label: string
}[] = [
  { key: 1, label: 'Pallet' },
  { key: 2, label: 'Box' },
  { key: 3, label: 'Container 20ft' },
  { key: 4, label: 'Container 40ft' }
]

const IDtoUnit = (id: number | null): string => {
  return UNIT_MODEL.find((unit) => unit.key === id)?.label || UNIT_MODEL[0].label
}

export default function PalletInfo() {
  const { receivablesFormCopy } = useReceivablesFormState()
  const dispatch = useReceivablesFormDispatch()

  const proxyItem = receivablesFormCopy?.items[0] // see README what is 'proxy item'
  const unitTypeValue = proxyItem?.UnitID || 0 // defaults to Pallet
  const unitCountValue = proxyItem?.UnitCount || 0

  const handleTypeChange = (event: any) => {
    setPalletInfo(dispatch, 'UnitID', event.target.value)
  }

  const handleCountChange = (event: any) => {
    setPalletInfo(dispatch, 'UnitCount', Number(event.target.value))
  }

  const unitId = (receivablesFormCopy?.items.length && receivablesFormCopy.items[0].UnitID) || null

  return (
    <Grid item xs={12} sx={{ marginTop: '20px', marginBottom: '10px' }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="unit-type-select-label" disabled>
              Unit Type
            </InputLabel>
            <Select onChange={handleTypeChange} label="Select Unit Type" value={unitTypeValue || ''}>
              {UNIT_MODEL.map((unit) => (
                <MenuItem value={unit.key} key={unit.key}>
                  {unit.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField
            onChange={handleCountChange}
            label={unitId === 3 || unitId === 4 ? 'Container Count' : IDtoUnit(unitId) + ' Count'}
            fullWidth
            type="number"
            InputLabelProps={{ shrink: true, disabled: true }}
            variant="outlined"
            value={unitCountValue}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
