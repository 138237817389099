import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled
} from '@mui/material'
import { CheckCircleOutlined, Close } from '@mui/icons-material'
import React, { useState } from 'react'
import { EmailSent, sendEmail } from '../../../graphQL/Email'
import { useReceivablesFormDispatch, useReceivablesFormState } from '../../../providers/ReceivablesFormProvider'
import { useReceivableOrderDispatch } from '../../../providers/ReceivableOrdersProvider'
import { getImageURLs, ImagePreview } from '../Photos/getImageURLs'
import { SaveFunction } from '../ActionButtons'
import { validateDate } from '../Header'
import { colors as qmColors } from '../../../theme/colorPalette'
import dayjs from 'dayjs'

interface EmailDraftProps {
  open: boolean
  closeDialog: (emailSent?: EmailSent) => void
}

const StyledOrderInformationGrid = styled(Grid)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  [theme.breakpoints.down('md')]: {
    fontSize: '1.1rem',
    fontWeight: 600
  }
}))

const EmailDraft: React.FC<EmailDraftProps> = ({ closeDialog, open }) => {
  const { receivablesFormCopy, receivablesForm, formChanged, userEmail } = useReceivablesFormState()
  const formDispatch = useReceivablesFormDispatch()
  const orderDispatch = useReceivableOrderDispatch()
  const [comment, setComment] = useState<string>('')
  const [isSending, setIsSending] = useState<boolean>(false)
  const [imagePreviews, setImagePreviews] = useState<ImagePreview[]>([])
  const [titleStatus, setTitleStatus] = useState('Expected Date')
  const [titleStatusDate, setTitleStatusDate] = useState<string | undefined>('')

  const defaultComments = receivablesFormCopy?.comments.map((comment) => comment.Comment).join('\n\n') || ''

  React.useEffect(() => {
    setComment(defaultComments)
  }, [defaultComments])

  React.useEffect(() => {
    if (receivablesFormCopy) {
      const getImages = async () => {
        setImagePreviews(await getImageURLs(receivablesFormCopy.photos))
      }
      getImages()
      // set it to expected regardless if any of the latter ones fail
      setTitleStatus('Expected')
      setTitleStatusDate(receivablesFormCopy.order.ExpectedDate)

      if (receivablesFormCopy.items.every((item) => item.Status === 3)) {
        if (validateDate(receivablesFormCopy.order.CountedDate!)) {
          setTitleStatus('Approved')
          setTitleStatusDate(receivablesFormCopy.order.CountedDate!)
        }
      } else if (receivablesFormCopy.items.every((item) => item.ActualQty === 0)) {
        if (validateDate(receivablesFormCopy.order.ExpectedDate!)) {
          setTitleStatus('Expected')
          setTitleStatusDate(receivablesFormCopy.order.ExpectedDate!)
        }
      } else if (validateDate(receivablesFormCopy.order.ReceivedDate!)) {
        setTitleStatus('Received')
        setTitleStatusDate(receivablesFormCopy.order.ReceivedDate!)
      } else {
        setTitleStatus('Expected')
        setTitleStatusDate(receivablesFormCopy.order.ExpectedDate!)
      }
    }
  }, [receivablesFormCopy])

  const onCommentChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value)
  }

  const sendEmailClicked = async () => {
    setIsSending(true)
    SaveFunction({
      formDispatch,
      orderDispatch,
      receivablesFormCopy,
      receivablesForm,
      saveForm: !!formChanged,
      userEmail: userEmail || '',
      fetchAgain: false
    })
    try {
      const countedDate = receivablesFormCopy!.order.CountedDate
      const emailResponse = await sendEmail({
        comment,
        items: receivablesFormCopy!.items.map((i) => ({
          CustItemID: i.CustItemID!,
          ItemID: `${i.ItemID}`,
          ItemName: i.ItemName!,
          Quantity: i.Quantity!,
          ActualQty: i.ActualQty!,
          UnitCount: i.UnitCount!,
          UnitID: i.UnitID!
        })),
        order: {
          CustName: receivablesFormCopy!.order.CustName,
          ExpectedDate: dayjs(receivablesFormCopy!.order.ExpectedDate).format('DD-MM-YYYY'),
          CountedDate: countedDate ? dayjs(countedDate).format('DD-MM-YYYY') : 'n/a',
          ReceivableOrderID: receivablesFormCopy!.order.ReceivableOrderID,
          CustEmail: receivablesFormCopy!.order.CustEmail,
          UserEmail: receivablesFormCopy!.order.UserEmail
        },
        photos: receivablesFormCopy!.photos!.map((p) => ({
          FileName: p.FileName,
          Path: p.Path,
          UploadedDate: p.UploadedDate
        })),
        emailSenderID: 1 // TODO - When Auth federation complete, Update this to Logged In User ID
      })
      closeDialog(emailResponse)
    } catch (e) {
      closeDialog()
    }
    setIsSending(false)
  }

  const DialogTitle = (props: any) => {
    const { children, ...other } = props
    return (
      <MuiDialogTitle {...other} sx={{ background: qmColors.qmBlue60, marginBottom: '10px' }}>
        <Typography color="#FFFFFF" fontSize="18px" fontWeight="600">
          {children}
        </Typography>
        <IconButton
          aria-label="close"
          sx={(theme) => ({ position: 'absolute', right: theme.spacing(1), top: theme.spacing(1), color: '#FFFFFF' })}
          onClick={() => closeDialog()}
          size="large"
        >
          <Close />
        </IconButton>
      </MuiDialogTitle>
    )
  }

  const unitInfo = receivablesFormCopy && receivablesFormCopy.items.length > 0 ? receivablesFormCopy.items[0] : undefined

  return (
    <>
      <Backdrop open={isSending} sx={(theme) => ({ zIndex: theme.zIndex.modal + 1 })}>
        <CircularProgress />
      </Backdrop>
      <Dialog fullWidth maxWidth="lg" scroll="paper" open={open} onClose={() => closeDialog()}>
        <DialogTitle>Send email to client</DialogTitle>
        <DialogContent sx={{ width: '100%' }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={(theme) => ({
                fontSize: '1.5rem',
                fontWeight: 600,
                color: theme.palette.primary.main,
                [theme.breakpoints.down('md')]: {
                  fontSize: '1.3rem',
                  fontWeight: 600
                }
              })}
            >
              {receivablesFormCopy?.order.CustName}
            </Grid>
            <StyledOrderInformationGrid item xs={6}>
              Order: {receivablesFormCopy?.order.ReceivableOrderID}
            </StyledOrderInformationGrid>
            <StyledOrderInformationGrid item xs={3}>
              {unitInfo?.UnitID === 1 && <>No. Pallets: </>}
              {unitInfo?.UnitID === 2 && <>No. Boxes: </>}
              {unitInfo?.UnitID === 3 && <>No. Container (20ft): </>}
              {unitInfo?.UnitID === 4 && <>No. Container (40ft): </>}
              {unitInfo?.UnitCount || ''}
            </StyledOrderInformationGrid>
            <StyledOrderInformationGrid data-testid={'emailOrderStatus'} item xs={3}>
              {titleStatus}:{titleStatusDate ? dayjs(titleStatusDate).format(' DD/MM/YYYY') : ''}
            </StyledOrderInformationGrid>
          </Grid>
          <Grid container spacing={3} mb="12px" mt="17px">
            <Grid item xs={12}>
              <TextField
                label="Message"
                InputLabelProps={{ shrink: true, disabled: true }}
                variant="outlined"
                onChange={onCommentChanged}
                multiline
                fullWidth
                minRows={5}
                maxRows={10}
                value={comment}
              />
            </Grid>
          </Grid>
          {imagePreviews.length > 0 && (
            <Grid container style={{ marginBottom: '5px' }}>
              <Grid item sx={{ marginBottom: '10px', color: qmColors.green, display: 'flex', alignItems: 'center', fontWeight: 600 }}>
                <CheckCircleOutlined />
                All images for this order will be attached to this email
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-start">
                  {imagePreviews.map((photo, i) => (
                    <Box
                      borderRadius="5px"
                      key={photo.ReceivableOrderPhotoID}
                      marginRight={2}
                      marginBottom={1}
                      width={100}
                      height={100}
                      style={{ paddingLeft: '5px' }}
                      component="img"
                      src={photo.url}
                      alt="Attached"
                      title={photo.FileName}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={5} marginBottom="1rem">
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: qmColors.qmBlue,
                      '& th': {
                        fontWeight: 600,
                        color: '#FFFFFF'
                      }
                    }}
                  >
                    <TableRow>
                      <TableCell>SKU</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Product</TableCell>
                      <TableCell>Expected Qty</TableCell>
                      <TableCell>Actual Qty</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {receivablesFormCopy?.items.map((item) => (
                      <TableRow key={item.ReceivableID}>
                        <TableCell>{item.CustItemID}</TableCell>
                        <TableCell>{item.ItemID}</TableCell>
                        <TableCell>{item.ItemName}</TableCell>
                        <TableCell>{item.Quantity}</TableCell>
                        <TableCell>{item.ActualQty}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow
                      sx={(theme) => ({
                        height: '40px',
                        [theme.breakpoints.down('md')]: {
                          height: '20px'
                        }
                      })}
                    />
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginRight: '5px' }}>
          <Button onClick={() => closeDialog()} variant="outlined" disabled={isSending}>
            Cancel
          </Button>
          <Button onClick={() => sendEmailClicked()} variant="contained" disabled={isSending} data-testid="draftSendEmail">
            Send email
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EmailDraft
