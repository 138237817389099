import { Checkbox, TableCell, TableRow, TextField, styled } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import React, { useCallback } from 'react'
import {
  setReceivableItemExpiryDate,
  setTableFields,
  TableFieldProps,
  useReceivablesFormDispatch,
  useReceivablesFormState
} from '../../../providers/ReceivablesFormProvider'
import Locations from './Locations'
import { colors as qmColors } from '../../../theme/colorPalette'
import { wamDateStringFormat } from '../../../utils'
import MoreAction from './MoreAction'
import { Receivable } from '../../../graphQL/ReceivablesForm'
import { ReceivableStatus } from '../../../models/ReceivableStatus'
import dayjs from 'dayjs'

const SmallTableCell = styled(TableCell)(({ theme }) => ({
  '&[data-disabled="true"]': {},
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.9rem',
    padding: '1em'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '0.72rem',
    padding: '0.5em'
  }
}))

const SkuTableCell = styled(TableCell)(({ theme }) => ({
  '&[data-disabled="true"]': {},
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.9rem',
    padding: '1em',
    justify: 'flex-end',
    width: '50px'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '0.72rem',
    padding: '0.5em',
    justify: 'flex-end',
    width: '50px'
  }
}))

const MediumTableCell = styled(TableCell)(({ theme }) => ({
  '&[data-disabled="true"]': {},
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.9rem',
    padding: '1em',
    justify: 'flex-end'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '0.72rem',
    padding: '0.5em',
    justify: 'flex-end'
  }
}))

const LargeTableCell = styled(TableCell)(({ theme }) => ({
  '&[data-disabled="true"]': {},
  [theme.breakpoints.down('md')]: {
    fontSize: '0.9rem',
    padding: '1em'
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.72rem',
    padding: '1em'
  }
}))

const LargeTableCell2 = styled(TableCell)(({ theme }) => ({
  '&[data-disabled="true"]': {},
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.9rem',
    padding: '1em',
    width: '100px'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '0.72rem',
    padding: '1em',
    width: '100px'
  }
}))

const GreenCheckbox = styled(Checkbox)({
  color: '#47A16D !important'
})

interface Props {
  receivableId: number
}

export default function ReceivableItemRow({ receivableId }: Props) {
  const { receivablesFormCopy } = useReceivablesFormState()
  const dispatch = useReceivablesFormDispatch()
  const [receivableItem, setReceivableItem] = React.useState<Receivable | undefined>(undefined)
  const [isChecked, setIsChecked] = React.useState<boolean>(false)
  const [batchValue, setBatchValue] = React.useState<string>('')
  const [expiryValue, setExpiryValue] = React.useState<dayjs.Dayjs | null | undefined>(undefined)
  const receivableOrderStatus = receivablesFormCopy?.order.Status || 1

  const showLocation = receivableOrderStatus === ReceivableStatus.PUTAWAY_IN_PROGRESS || receivableOrderStatus === ReceivableStatus.APPROVED

  React.useEffect(() => {
    const targetItem = receivablesFormCopy?.items.find((rec) => rec.ReceivableID === receivableId)
    setReceivableItem(targetItem)
    if (targetItem) {
      setBatchValue(targetItem.Storage[0]?.Batch || '')
      setExpiryValue(['0000-00-00', undefined, null].includes(targetItem.Storage[0]?.Expiry) ? undefined : dayjs(targetItem.Storage[0]?.Expiry))
      if (receivableOrderStatus === ReceivableStatus.AWAITING_STOCK || receivableOrderStatus === ReceivableStatus.COUNT_IN_PROGRESS) {
        setIsChecked(targetItem.Status === ReceivableStatus.PUTAWAY_IN_PROGRESS)
      }
      if (receivableOrderStatus === ReceivableStatus.PUTAWAY_IN_PROGRESS || receivableOrderStatus === ReceivableStatus.APPROVED) {
        setIsChecked(targetItem.Status === ReceivableStatus.APPROVED)
      }
    }
  }, [receivablesFormCopy?.items, receivableId, receivableOrderStatus])

  const onTableFieldChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newField: TableFieldProps = event.target
      setTableFields(dispatch, newField)
    },
    [dispatch]
  )

  const onDateFieldChanged = useCallback(
    (date: dayjs.Dayjs | null) => {
      if (date && !isNaN(date.millisecond())) {
        setReceivableItemExpiryDate(dispatch, receivableId, date.toDate())
      }
    },
    [dispatch, receivableId]
  )

  return React.useMemo(() => {
    if (receivableItem) {
      return (
        <TableRow
          key={receivableId}
          data-testid={`table-row${receivableId}`}
          sx={{
            '&[data-highlight="true"]': {
              backgroundColor: qmColors.qmBlue05
            },
            '& td': {
              borderBottom: `1px solid ${qmColors.qmBlue30}`
            },
            ...(receivableItem.ActualQty < receivableItem.Quantity && {
              backgroundColor: `${qmColors.paleRed} !important`,
              '& td': {
                color: qmColors.errorRed
              },
              '& td:first-of-type': {
                borderLeft: '5px',
                borderLeftColor: qmColors.errorRed,
                borderLeftStyle: 'solid'
              }
            })
          }}
          data-highlight={isChecked}
        >
          <SkuTableCell align="left" size="small" data-disabled={isChecked}>
            {receivableItem.CustItemID}
          </SkuTableCell>
          <LargeTableCell2 align="left" size="medium" data-disabled={isChecked}>
            {receivableItem.ItemDescription}
          </LargeTableCell2>
          <SmallTableCell align="right" size="small" sx={{ width: '8vw' }} data-disabled={isChecked}>
            {receivableItem.Quantity}
          </SmallTableCell>
          <MediumTableCell sx={{ width: '8vw' }} align="right" size="small">
            {isChecked
              ? (
              <>{receivableItem.ActualQty}</>
                )
              : (
              <TextField
                name={`${receivableId}-ActualQty`}
                data-testid={`qty-input${receivableId}`}
                size="small"
                variant="outlined"
                inputProps={{ min: 0, style: { textAlign: 'right' } }}
                value={receivableItem.ActualQty ? receivableItem.ActualQty : ''}
                onChange={onTableFieldChange}
                InputProps={{
                  sx: (theme) => ({
                    input: {
                      '&[data-disabled="true"]': {},
                      textAlign: 'center',
                      [theme.breakpoints.down('lg')]: {
                        fontSize: '0.9rem',
                        paddingTop: '1em',
                        paddingBottom: '1em'
                      },
                      [theme.breakpoints.down('md')]: {
                        fontSize: '0.72rem',
                        paddingTop: '1em',
                        paddingBottom: '1em'
                      }
                    }
                  })
                }}
                onWheel={(event) => event.currentTarget.blur()}
              />
                )}
          </MediumTableCell>
          <MediumTableCell
            align="right"
            size="small"
            sx={{
              width: '10vw'
            }}
          >
            {isChecked
              ? (<>{batchValue}</>)
              : (
              <TextField
                name={`${receivableId}-Batch`}
                value={batchValue}
                onChange={onTableFieldChange}
                size="small"
                variant="outlined"
                InputProps={{
                  sx: (theme) => ({
                    input: {
                      '&[data-disabled="true"]': {},
                      textAlign: 'center',
                      [theme.breakpoints.down('lg')]: {
                        fontSize: '0.9rem',
                        paddingTop: '1em',
                        paddingBottom: '1em'
                      },
                      [theme.breakpoints.down('md')]: {
                        fontSize: '0.72rem',
                        paddingTop: '1em',
                        paddingBottom: '1em'
                      }
                    }
                  })
                }}
                inputProps={{ style: { textAlign: 'right' } }}
              />
                )}
          </MediumTableCell>
          <LargeTableCell
            align="left"
            size="small"
            sx={{
              width: '14vw'
            }}
          >
            {isChecked
              ? (
              <>{wamDateStringFormat(expiryValue?.format('YYYY-MM-DD'))}</>
                )
              : (
              <DatePicker
                onChange={onDateFieldChanged}
                value={expiryValue}
                format="DD/MM/YY"
                slotProps={{
                  textField: { variant: 'outlined', size: 'small', name: `${receivableId}-Expiry` },
                  toolbar: { hidden: false }
                }}
              />
                )}
          </LargeTableCell>
          {showLocation && (
            <LargeTableCell2
              align="left"
              size="small"
              sx={{
                width: '10vw'
              }}
            >
              <Locations receivableId={receivableId} disabled={isChecked} key={receivableId} />
            </LargeTableCell2>
          )}
          <MediumTableCell
            align="center"
            size="small"
            padding="checkbox"
          >
            <GreenCheckbox onChange={onTableFieldChange} name={`${receivableId}-Status`} checked={isChecked || false} />
          </MediumTableCell>
          <TableCell align="center" size="small" sx={{ width: '5vw' }}>
            <MoreAction receivableId={receivableId} key={receivableId} />
          </TableCell>
        </TableRow>
      )
    }

    return null
  }, [batchValue, expiryValue, isChecked, onDateFieldChanged, onTableFieldChange, receivableId, receivableItem, showLocation])
}
