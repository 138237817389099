import { Amplify, Auth } from 'aws-amplify'

const getIdToken = async () => ({
  Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
})

export const oauth = {
  domain: process.env.REACT_APP_OAUTH_DOMAIN,
  redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN,
  redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT,
  responseType: 'token',
  scope: ['openid']
}

const awsconfig: any = Amplify.configure({
  API: {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_OAUTH_GRAPHQL_ENDPOINT,
    aws_appsync_region: 'ap-southeast-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    graphql_headers: getIdToken
  },
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    oauth,
    region: 'ap-southeast-2',
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEBCLIENT_ID
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_STORAGE_S3_BUCKET,
      region: 'ap-southeast-2'
    }
  }
})

export const constructUrl = {
  url: `https://${oauth.domain}/login?redirect_uri=${oauth.redirectSignIn}&response_type=${oauth.responseType}&client_id=${awsconfig.Auth.userPoolWebClientId}`
}

export const redirectToLogin = () => {
  window.location.assign(constructUrl.url)
}

export default awsconfig
