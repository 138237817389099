import { Box, CircularProgress, IconButton, TextField, styled } from '@mui/material'
import { Close, Save } from '@mui/icons-material'
import React from 'react'
import { submitComments } from '../../../graphQL/ReceivablesComments'
import {
  addComment,
  setSnackbarNotification,
  setOpenNoteDialog,
  useReceivablesFormDispatch,
  useReceivablesFormState
} from '../../../providers/ReceivablesFormProvider'
import { colors as qmColors } from '../../../theme/colorPalette'

const ActionsButtonContainer = styled(Box)(() => ({
  borderRight: `1px solid ${qmColors.grey50}`,
  borderBottom: `1px solid ${qmColors.grey50}`,
  borderLeft: `1px solid ${qmColors.grey50}`,
  borderRadius: '0 0 4px 4px',
  float: 'right',
  paddingLeft: '1.1em',
  paddingRight: '0.5em'
}))

export default function NoteTextbox() {
  const { receivablesForm, openNoteDialog, selectedReceivableIDForComment } = useReceivablesFormState()
  const dispatch = useReceivablesFormDispatch()
  const selectedReceivableItem = receivablesForm?.items.find((rec) => rec.ReceivableID === selectedReceivableIDForComment)
  const initialNote = selectedReceivableItem ? `${selectedReceivableItem.CustItemID} ${selectedReceivableItem.ItemName}\n` : ''
  const [textValue, setTextValue] = React.useState(initialNote)
  const [noteLoading, setNoteLoading] = React.useState(false)

  React.useEffect(() => {
    if (initialNote !== '') {
      setTextValue(initialNote)
    }
  }, [initialNote])

  const closeDialog = React.useCallback(() => {
    setOpenNoteDialog(dispatch, false)
    setTextValue('')
  }, [dispatch])

  const onConfirm = React.useCallback(async () => {
    try {
      setNoteLoading(true)
      const newComment = await submitComments(Number(receivablesForm?.order.ReceivableOrderID), textValue)
      addComment(dispatch, newComment)
      setTextValue('')
      setNoteLoading(false)
      setSnackbarNotification(dispatch, true, 'success', 'Comment Added')
    } catch (e) {
      setSnackbarNotification(dispatch, true, 'error', 'Error adding Comment')
    }
    closeDialog()
  }, [dispatch, closeDialog, receivablesForm?.order.ReceivableOrderID, textValue])

  const emptyText = textValue.trim() === ''

  return React.useMemo(
    () => (
      <>
        {openNoteDialog
          ? (
          <div>
            {noteLoading && <CircularProgress />}
            {!noteLoading && (
              <>
                <TextField
                  label="Enter your notes here"
                  fullWidth
                  InputLabelProps={{ shrink: true, disabled: true }}
                  variant="outlined"
                  multiline={true}
                  minRows={4}
                  value={textValue}
                  onChange={(event) => setTextValue(event.target.value)}
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderRadius: 0
                    }
                  }}
                  data-testid="textfield-notes"
                  inputRef={(input) => {
                    if (input != null) {
                      input.focus()
                    }
                  }}
                />
                <ActionsButtonContainer>
                  <IconButton
                    sx={{
                      color: qmColors.green,
                      marginRight: '0.5em',
                      '&:disabled': {
                        color: qmColors.grey
                      }
                    }}
                    onClick={onConfirm}
                    disabled={emptyText}
                    size="large"
                  >
                    <Save />
                  </IconButton>
                  <IconButton style={{ color: qmColors.green }} onClick={closeDialog} size="large">
                    <Close />
                  </IconButton>
                </ActionsButtonContainer>
              </>
            )}
          </div>
            )
          : null}
      </>
    ),
    [openNoteDialog, noteLoading, textValue, onConfirm, emptyText, closeDialog]
  )
}
