import { IconButton } from '@mui/material'
import { EmailOutlined } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import {
  useReceivablesFormDispatch,
  useReceivablesFormState,
  setOpenEmailDraftDialog,
  setShowEmailSentNotification
} from '../../../providers/ReceivablesFormProvider'

import React from 'react'
import EmailDraft from '../Email'
import { EmailSent } from '../../../graphQL/Email'

export default function HeaderEmailButton() {
  const history = useHistory()
  const { openEmailDraftDialog } = useReceivablesFormState()
  const formDispatch = useReceivablesFormDispatch()

  const closeEmailDraft = React.useCallback(
    (email?: EmailSent) => {
      if (email) {
        setShowEmailSentNotification(formDispatch, true)
        setTimeout(() => {
          setShowEmailSentNotification(formDispatch, false)
          history.push('/')
        }, 3000) // 3 second delay to automatically close the confirmation
      }
      setOpenEmailDraftDialog(formDispatch, false)
    },
    [formDispatch, history]
  )

  return React.useMemo(() => {
    return (
      <>
        <IconButton
          sx={{ color: '#FFFFFF' }}
          onClick={() => setOpenEmailDraftDialog(formDispatch, true)}
          data-testid="button-openEmailDraft"
          size="large"
        >
          <EmailOutlined />
        </IconButton>
        <EmailDraft closeDialog={closeEmailDraft} open={openEmailDraftDialog} />
      </>
    )
  }, [formDispatch, openEmailDraftDialog, closeEmailDraft])
}
