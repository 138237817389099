import { IconButton, Popover, Button, styled, IconButtonProps, ButtonProps } from '@mui/material'
import { ExpandMoreOutlined, ExpandLessOutlined } from '@mui/icons-material'
import React from 'react'
import { Auth } from 'aws-amplify'

const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: theme.palette.primary.main
}))

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600
}))

export default function UserExpandMoreButton() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  return <>
    <StyledIconButton
      aria-describedby={id}
      onClick={handleClick}
      size="large">
      {open ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
    </StyledIconButton>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <div style={{
        padding: '5px 15px'
      }}>
        <StyledButton onClick={() => Auth.signOut()}>
          Logout
        </StyledButton>
      </div>
    </Popover>
  </>
}
