import { Auth } from 'aws-amplify'
import * as React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { redirectToLogin } from '../aws-exports'

interface IPrivateRouteState {
  authenticated: boolean
  isAuthenticating: boolean
}

function PrivateRoute(props: RouteProps) {
  const [state, setState] = React.useState<IPrivateRouteState>({ authenticated: false, isAuthenticating: true })
  React.useEffect(() => {
    const performAuth = async () => {
      try {
        const session = await Auth.currentSession()
        if (session.isValid()) {
          const nextState = {
            authenticated: true,
            isAuthenticating: false
          }
          setState(nextState)
        }
      } catch (e) {
        console.log('Auth Error', e)
        const nextState = {
          authenticated: false,
          isAuthenticating: false
        }
        setState(nextState)
      }
    }
    performAuth()
  }, [])

  const { authenticated, isAuthenticating } = state
  const { ...rest } = props

  if (authenticated) {
    return <Route {...rest} component={props.component} />
  }

  if (isAuthenticating) {
    return null
  }

  redirectToLogin()
  return null
}

export default PrivateRoute
