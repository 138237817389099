import { API, graphqlOperation } from 'aws-amplify'
import { ReceivableFormInput } from './ReceivablesForm'

const UPDATE_RECEIVABLE_ORDER_STATUS = `mutation updateReceivableOrderStatus(
  $receivableOrderId: Int, 
  $status: Int, 
  $email: String, 
  $singleReceivable: Boolean, 
  $receivableUniequeID: Int, 
  $receivableFormInput: ReceivableFormInput)
  {updateReceivableOrderStatus(
    receivableOrderId: $receivableOrderId, 
    status: $status, 
    email: $email, 
    singleReceivable: $singleReceivable, 
    receivableUniequeID: $receivableUniequeID, 
    receivableFormInput: $receivableFormInput)
}`

export const UpdateReceivableOrderStatus = async (
  receivableOrderId: number,
  status: number,
  email: string,
  singleReceivable?: boolean,
  receivableUniequeID?: number,
  receivableFormInput?: ReceivableFormInput
) => {
  try {
    // const response = await API.graphql(
    await API.graphql(
      graphqlOperation(UPDATE_RECEIVABLE_ORDER_STATUS, {
        receivableOrderId,
        status,
        email,
        singleReceivable,
        receivableUniequeID,
        receivableFormInput
      })
    )
  } catch (e) {
    console.log(e) // TODO - Add notification to errors
  }
}
