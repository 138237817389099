import { API, graphqlOperation } from 'aws-amplify'

export interface Site {
    SiteID: number
    SiteName: string
    SiteDescription: string
}

const GET_SITES = `query getSites($userID: String) {
    getSites(userID: $userID) {
        SiteID
        SiteName
        SiteDescription
    }
}`

interface GET_SITES_RESULT {
    data: {
        getSites: Site[]
    }
}

export async function GetSites(userID: string): Promise<Site[]> {
  let Sites: Site[] = []
  try {
    const result = (await API.graphql(graphqlOperation(GET_SITES, { userID }))) as GET_SITES_RESULT
    Sites = result.data.getSites
  } catch (e) {
    console.log(e)
  }
  return Sites
}
