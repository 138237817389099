import { IconButton } from '@mui/material'
import { SaveOutlined } from '@mui/icons-material'
import { ReceivableForm, ReceivableFormInput, UpdateReceivableForm } from '../../../graphQL/ReceivablesForm'
import {
  Dispatch,
  setIsLoading,
  setSnackbarNotification,
  storeUnsavedForm,
  useReceivablesFormDispatch,
  useReceivablesFormState
} from '../../../providers/ReceivablesFormProvider'
import {
  setPullOrderAgain,
  useReceivableOrderDispatch,
  Dispatch as OrderDispatch,
  useReceivableOrderState
} from '../../../providers/ReceivableOrdersProvider'
import React from 'react'
import { qmNowDateString } from '../../../utils'

export async function SaveFunction({
  formDispatch,
  orderDispatch,
  receivablesFormCopy,
  receivablesForm,
  saveForm,
  userEmail,
  fetchAgain
}: {
  formDispatch: Dispatch
  orderDispatch: OrderDispatch
  receivablesFormCopy: ReceivableForm | undefined
  receivablesForm: ReceivableForm | undefined
  saveForm: boolean
  userEmail: string
  fetchAgain: boolean
}) {
  setIsLoading(formDispatch, true)

  if (!saveForm) {
    return null
  }

  receivablesFormCopy!.items.forEach((_, index) => {
    if (receivablesForm?.items[index].Status === 1) {
      receivablesFormCopy!.items[index].ReceivedDate = qmNowDateString()
      receivablesFormCopy!.items[index].ReceivedBy = userEmail
    }
  })

  const result = await UpdateReceivableForm({
    order: receivablesFormCopy?.order,
    items: receivablesFormCopy?.items
  } as ReceivableFormInput)
  if (result === 'Success' && fetchAgain) {
    await setPullOrderAgain(orderDispatch, true)
  }
  setIsLoading(formDispatch, false)
  if (result === 'Success') {
    setSnackbarNotification(formDispatch, true, 'success', 'Form Saved')
  } else {
    storeUnsavedForm(formDispatch)
    setSnackbarNotification(formDispatch, true, 'error', 'Session expired, please refresh the page to re-login')
  }
}

export default function HeaderSaveButton() {
  const { receivablesFormCopy, receivablesForm, isLoading, formChanged, userEmail } = useReceivablesFormState()
  const formDispatch = useReceivablesFormDispatch()
  const orderDispatch = useReceivableOrderDispatch()
  const { providerLocalHandlingTime } = useReceivableOrderState()

  return React.useMemo(() => {
    return (
      <IconButton
        sx={{ color: '#FFFFFF' }}
        onClick={() =>
          SaveFunction({
            formDispatch,
            orderDispatch,
            receivablesFormCopy,
            receivablesForm,
            saveForm: !!formChanged,
            userEmail: userEmail || '',
            fetchAgain: true
          })
        }
        disabled={isLoading || (!formChanged && !providerLocalHandlingTime)}
        size="large"
      >
        <SaveOutlined />
      </IconButton>
    )
  }, [formChanged, userEmail, isLoading, providerLocalHandlingTime, formDispatch, orderDispatch, receivablesForm, receivablesFormCopy])
}
