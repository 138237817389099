import { Button, styled } from '@mui/material'
import { AddOutlined } from '@mui/icons-material'
import React from 'react'

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'right',
  alignItems: 'center'
}))

interface Props {
  buttonText: string
  onClick: () => void
  disabled?: boolean
  'data-testid'?: string
}

export default function AddButton({ buttonText, onClick, disabled = false, ...other }: Props) {
  return React.useMemo(() => (
    <StyledDiv>
      <Button
        {...other}
        sx={(theme) => ({
          textTransform: 'none',
          color: theme.palette.success.main,
          fontWeight: 600
        })}
        onClick={onClick}
        disabled={disabled}
      >
        <AddOutlined /> {buttonText}
      </Button>
    </StyledDiv>
  ), [buttonText, onClick, other, disabled])
}
