import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorker.register()

interface ChoiceResult {
  outcome: string
}

interface IEvent {
  prompt(): void
  userChoice: Promise<ChoiceResult>
}

let deferredPrompt: IEvent | null
const addBtn = document.querySelector('.addButton') as HTMLElement

if (addBtn) {
  addBtn.style.display = 'none'

  window.addEventListener('beforeinstallprompt', (event) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    event.preventDefault()
    // Stash the event so it can be triggered later.
    deferredPrompt = Object(event) as IEvent
    // Update UI to notify the user they can add to home screen
    addBtn.style.display = 'block'

    addBtn.addEventListener('click', async (mouseEvent) => {
      // hide our user interface that shows our A2HS button
      addBtn.style.display = 'none'

      if (deferredPrompt) {
        // Show the prompt
        deferredPrompt.prompt()
        // Wait for the user to respond to the prompt
        const choiceResult = await deferredPrompt.userChoice
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt')
        } else {
          console.log('User dismissed the A2HS prompt')
        }
        deferredPrompt = null
      }
    })
  })
}
