import { Storage } from 'aws-amplify'
import { Photo } from '../../../graphQL/ReceivablesPhotos'

export interface ImagePreview extends Photo {
  url: string
}

export const getImageURLs = async(photos: Photo[]): Promise<ImagePreview[]> => {
  const images: ImagePreview[] = await Promise.all(
    photos.map(async(image) => {
      const signedImage = await Storage.get(image.Path)
      return {
        url: signedImage as string,
        ...image
      }
    })
  )
  return images
}
