import React from 'react'
import { DataGrid, GridFilterModel, GridRowParams, GridSortModel } from '@mui/x-data-grid'
import { useHistory } from 'react-router'
import { setGridFilterModel, setGridSortModel, setReceivableOrdersPresistTab, useReceivableOrderDispatch, useReceivableOrderState } from '../../../providers/ReceivableOrdersProvider'
import Columns from './Columns'
import { checkDateState, wamDateStringFormat } from '../../../utils/index'
import { colors as qmColors } from '../../../theme/colorPalette'
import { ReceivableStatusKeyLabel } from '../../../models/ReceivableStatus'
import { SiteID } from '../../../models/Site'
import { styled } from '@mui/material'

const StyledDiv = styled('div')(() => ({
  '& .MuiDataGrid-columnHeaderDraggableContainer ': {
    width: 'auto',
    '& .MuiIconButton-root': {
      color: '#FFFFFF'
    }
  },
  '& .MuiDataGrid-columnHeader': {
    display: 'block'
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: 0
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600,
    fontSize: '17px'
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: qmColors.qmBlue,
    color: '#FFFFFF'
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none'
  },
  '& .MuiDataGrid-cell': {
    fontSize: '15px',
    borderBottom: `1px solid ${qmColors.qmBlue30} !important`
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: `1px solid ${qmColors.qmBlue30} !important`
  },
  '& .MuiTablePagination-displayedRows': {
    color: qmColors.qmBlue60
  }
}))

function ReceivableOrderGrid({ index }: { index: number }) {
  const history = useHistory()
  const state = useReceivableOrderState()
  const orderDispatch = useReceivableOrderDispatch()
  const gridColumns = Columns(index)
  const persistedFilter = state.gridFilterModel?.items.find(filter => gridColumns.find(col => col.field === filter.field)) ? state.gridFilterModel : undefined
  const tabGridSortModel = state.tabSortModels.find(tab => tab.index === index)
  const gridSortModel = tabGridSortModel?.gridSortModel

  const persistedSort = React.useMemo(() => (gridSortModel?.find(sort => gridColumns.find(col => col.field === sort.field))
    ? gridSortModel
    : undefined),
  [gridColumns, gridSortModel])

  const onGridFilterModelChange = React.useCallback((model: GridFilterModel) => {
    setGridFilterModel(orderDispatch, model)
  }, [orderDispatch])
  const onGridSortModelChange = React.useCallback((model: GridSortModel) => {
    if (JSON.stringify(gridSortModel) !== JSON.stringify(model)) {
      setGridSortModel(orderDispatch, model, index)
    }
  }, [gridSortModel, orderDispatch, index])

  const onRowClick = React.useCallback((params: GridRowParams) => {
    setReceivableOrdersPresistTab(orderDispatch, undefined, state.activeTab)
    history.push(`/receivable/${params.row.id}`)
  }, [orderDispatch, state.activeTab, history])

  const filteredReceivableOrders = (state.receivableOrders || [])
    .filter((receivableOrder) => {
      if (state.selectedSite === 0) {
        // display the orders for all the users available sites
        const sites = state.availableSites ? state.availableSites.map(site => site.SiteID) : []
        return sites.includes(receivableOrder.SiteID)
      } else {
        return receivableOrder.SiteID === state.selectedSite
      }
    })
    .filter((receivableOrder) => receivableOrder.Status === index + 1)
    .filter((receivableOrder) => {
      const keyword = state.searchOrderByClientKeyword.toLowerCase()
      const { CustName, ReceivableOrderID, Reference, Supplier, ExpectedDate, CountedDate, ReceivedDate, ContainerSize, Status } = receivableOrder
      const dateCheck =
        Status === 1
          ? wamDateStringFormat(ExpectedDate).toLowerCase().includes(keyword)
          : Status === 2
            ? wamDateStringFormat(ReceivedDate).toLowerCase().includes(keyword)
            : Status === 3 || Status === 4
              ? wamDateStringFormat(CountedDate).toLowerCase().includes(keyword)
              : false
      const containerValue = ContainerSize === 0 ? 'N/A' : `${ContainerSize}ft`

      return (
        CustName.toLowerCase().includes(keyword) ||
        ReceivableOrderID.toString().includes(keyword) ||
        Reference.toLowerCase().includes(keyword) ||
        Supplier.toLowerCase().includes(keyword) ||
        dateCheck ||
        containerValue.toLowerCase().includes(keyword)
      )
    })

  const rows = React.useMemo(() => (
    filteredReceivableOrders !== undefined
      ? filteredReceivableOrders?.map((receivableOrder) => {
        return {
          client: receivableOrder.CustName,
          id: receivableOrder.ReceivableOrderID,
          reference: receivableOrder.Reference,
          supplier: receivableOrder.Supplier,
          expectedDate: receivableOrder.ExpectedDate,
          countedDate: receivableOrder.CountedDate,
          receivedDate: receivableOrder.ReceivedDate,
          containerSize: receivableOrder.ContainerSize,
          status: ReceivableStatusKeyLabel.find(rec => rec.key === receivableOrder.Status)?.label || '',
          overdueDateExpectedDate: checkDateState(receivableOrder.ExpectedDate),
          siteId: SiteID[receivableOrder.SiteID]
        }
      })
      : []),
  [filteredReceivableOrders])

  return React.useMemo(() => (
    <StyledDiv>
      <DataGrid
        rows={rows}
        columns={gridColumns}
        columnBuffer={8}
        pageSizeOptions={[100]}
        loading={state.isLoading}
        autoHeight
        onRowClick={onRowClick}
        data-testid={`tabpanel-${index}`}
        onFilterModelChange={onGridFilterModelChange}
        onSortModelChange={onGridSortModelChange}
        filterModel={persistedFilter}
        sortModel={persistedSort}
        sx={{
          backgroundColor: '#FFFFFF',
          border: 0,
          marginTop: '1px'
        }}
      />
    </StyledDiv>
  ), [gridColumns, index, onGridFilterModelChange, onGridSortModelChange, onRowClick, persistedFilter, persistedSort, rows, state.isLoading])
}

export default ReceivableOrderGrid
