import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, Typography } from '@mui/material'
import { FC } from 'react'
import { ExpandMore, TimerOutlined } from '@mui/icons-material'
import { useReceivablesFormState, setOpenTimeHandlingDialog, useReceivablesFormDispatch } from '../../../providers/ReceivablesFormProvider'
import { wamDateStringFormat } from '../../../utils'
import AddButton from '../../../components/Buttons/AddButton'
import HandlingTimeDialog from './HandlingTimeDialog'
import UpdateHandlingTime from './UpdateHandlingTime'
import DeleteHandlingTime from './DeleteHandlingTime'

export const HeaderAddHandlingTimeButton: FC = () => {
  const dispatch = useReceivablesFormDispatch()
  return (
    <IconButton style={{ color: '#FFFFFF' }} onClick={() => setOpenTimeHandlingDialog(dispatch, true)} size="large">
      <TimerOutlined />
    </IconButton>
  )
}

const AddHandlingTime: FC = () => {
  const { receivablesForm } = useReceivablesFormState()
  const dispatch = useReceivablesFormDispatch()

  const priorItemCountingTime: number = receivablesForm?.items.reduce((accumulator, item) => {
    if (typeof item.CountingTime === 'number') {
      return accumulator + item.CountingTime
    }
    return accumulator
  }, 0) || 0
  const remoteHandlingTimes: number = receivablesForm?.handling.reduce((accumulator, item) => {
    if (typeof item.HandlingTime === 'number') {
      return accumulator + item.HandlingTime
    }
    return accumulator
  }, 0) || 0

  const totalHandlingTime = priorItemCountingTime + remoteHandlingTimes
  const displayedHandlingTimes: {
    handlingTimeId: string
    handlingDate: string
    handlingTime: number
  }[] = []

  if (priorItemCountingTime > 0) {
    displayedHandlingTimes.push({
      handlingTimeId: 'prior',
      handlingDate: 'prior',
      handlingTime: priorItemCountingTime
    })
  }

  if (receivablesForm?.handling) {
    displayedHandlingTimes.push(
      ...receivablesForm?.handling.map((handling) => ({
        handlingTimeId: handling.ReceivableOrderHandlingID.toString(),
        handlingDate: wamDateStringFormat(handling.HandlingDate),
        handlingTime: handling.HandlingTime
      }))
    )
  }

  const onAddHandlingTimeButtonClick = () => setOpenTimeHandlingDialog(dispatch, true)

  return (
    <Grid item xs={6}>
      <Box border='1px solid #bdbdbd' borderRadius='5px' padding='0 10px 10px 10px'>
        <Accordion defaultExpanded
        sx={(theme) => ({
          padding: '0px',
          minHeight: theme.typography.pxToRem(60),
          boxShadow: 'none'
        })}>
          <AccordionSummary expandIcon={<ExpandMore />} sx={{
            minHeight: '48px',
            maxHeight: '48px'
          }}>
            <Typography fontSize="14px" lineHeight="21px" fontWeight={700}>
              Handling Time
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {displayedHandlingTimes.map((handling) => (
              <Grid container key={handling.handlingTimeId} justifyContent='space-between'>
                <Grid item display='flex' alignItems='center'>
                  <Grid item sx={(theme) => ({
                    paddingRight: '8px',
                    color: theme.palette.grey[100]
                  })}>
                    {handling.handlingDate === 'prior' ? 'prior' : wamDateStringFormat(handling.handlingDate)}
                  </Grid>
                  <Grid item>
                    {handling.handlingTime} minutes
                  </Grid>
                </Grid>
                <Grid item gap='15px' display='flex'>
                  <UpdateHandlingTime handlingTimeId={handling.handlingTimeId} currentHandlingTime={handling.handlingTime} />
                  <DeleteHandlingTime handlingTimeId={handling.handlingTimeId} currentHandlingTime={handling.handlingTime} />
                </Grid>
              </Grid>
            ))}
          </AccordionDetails>
        </Accordion>
        <Box display='flex' alignItems='center' justifyContent='space-between' padding='0px 16px'>
          <span>Total: {totalHandlingTime} Minutes</span>
          <AddButton onClick={onAddHandlingTimeButtonClick} buttonText='Add Time' data-testid="addButton-add-time" />
        </Box>
        <HandlingTimeDialog />
      </Box>
    </Grid>
  )
}

export default AddHandlingTime
