import React from 'react'
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid'
import { ReceivableOrder } from '../graphQL/ReceivableOrder'
import { Site } from '../graphQL/Sites'
import { SiteID } from '../models/Site'

type Action =
  | { type: 'setReceivableOrders'; payload: { receivableOrders?: ReceivableOrder[] } }
  | { type: 'setReceivableOrdersPresistTab'; payload: { receivableOrders?: ReceivableOrder[], inActiveTab?: number } }
  | { type: 'resetReceivableOrders' }
  | { type: 'setIsLoading'; payload: { value: boolean } }
  | { type: 'setPullOrderAgain'; payload: { value: boolean } }
  | { type: 'setProviderLocalHandlingTime'; payload: { value: number } }
  | { type: 'resetProviderLocalHandlingTime' }
  | { type: 'setPullOrderAgain'; payload: { value: boolean } }
  | { type: 'setActiveTab'; payload: { value: number } }
  | { type: 'setSearchOrderByClientKeyword'; payload: { keyword: string } }
  | { type: 'setGridFilterModel'; payload: { gridFilterModel: GridFilterModel } }
  | { type: 'setGridSortModel'; payload: { gridSortModel: GridSortModel, index: number } }
  | { type: 'setSelectedSite'; payload: { selectedSite: SiteID } }
  | { type: 'setAvailableSites'; payload: { availableSites: Site[] } }

export type Dispatch = (action: Action) => void
type State = {
  receivableOrders?: ReceivableOrder[]
  isLoading: boolean
  pullOrderAgain?: boolean
  providerLocalHandlingTime?: number
  activeTab?: number
  searchOrderByClientKeyword: string
  gridFilterModel?: GridFilterModel
  tabSortModels: {
    index: number,
    gridSortModel?: GridSortModel
  }[],
  selectedSite: SiteID,
  availableSites: Site[]
}

type Props = { children: React.ReactNode; receivableOrders?: ReceivableOrder[] | undefined }

const ReceivableOrdersStateContext = React.createContext<State | undefined>(undefined)
const ReceivableOrdersDispatchContext = React.createContext<Dispatch | undefined>(undefined)

function receivableOrdersReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setReceivableOrders': {
      return {
        ...state,
        receivableOrders: action.payload.receivableOrders,
        isLoading: false
      }
    }

    case 'setReceivableOrdersPresistTab': {
      return {
        ...state,
        receivableOrders: action.payload.receivableOrders,
        isLoading: false,
        activeTab: action.payload.inActiveTab
      }
    }

    case 'resetReceivableOrders': {
      return {
        ...state,
        receivableOrders: [],
        isLoading: false
      }
    }

    case 'setIsLoading': {
      return {
        ...state,
        isLoading: action.payload.value
      }
    }

    case 'setPullOrderAgain': {
      return {
        ...state,
        pullOrderAgain: action.payload.value
      }
    }

    case 'setProviderLocalHandlingTime': {
      return {
        ...state,
        providerLocalHandlingTime: action.payload.value
      }
    }

    case 'resetProviderLocalHandlingTime': {
      return {
        ...state,
        providerLocalHandlingTime: undefined
      }
    }

    case 'setActiveTab': {
      return {
        ...state,
        activeTab: action.payload.value
      }
    }

    case 'setSearchOrderByClientKeyword': {
      return {
        ...state,
        searchOrderByClientKeyword: action.payload.keyword
      }
    }

    case 'setGridFilterModel': {
      return {
        ...state,
        gridFilterModel: action.payload.gridFilterModel
      }
    }

    case 'setGridSortModel': {
      const { gridSortModel, index } = action.payload
      const existingSortModels = [...state.tabSortModels]
      const targetGridSortModel = existingSortModels.find(sortModel => sortModel.index === index)
      if (targetGridSortModel) {
        targetGridSortModel.gridSortModel = gridSortModel
        return {
          ...state,
          tabSortModels: existingSortModels
        }
      }
      return {
        ...state
      }
    }

    case 'setSelectedSite': {
      return {
        ...state,
        selectedSite: action.payload.selectedSite
      }
    }

    case 'setAvailableSites': {
      return {
        ...state,
        availableSites: action.payload.availableSites
      }
    }

    default: {
      throw new Error(`Unhandled action type: ${action!}`)
    }
  }
}

function ReceivableOrdersProvider({ children, receivableOrders }: Props) {
  const initialProps: State = {
    receivableOrders,
    isLoading: false,
    pullOrderAgain: false,
    providerLocalHandlingTime: undefined,
    activeTab: 0,
    searchOrderByClientKeyword: '',
    gridFilterModel: undefined,
    tabSortModels: [
      {
        index: 0, // awaiting stock (Pending) tab
        gridSortModel: [{ field: 'id', sort: 'asc' }]
      },
      {
        index: 1, // count in progress (awaiting) tab
        gridSortModel: undefined
      },
      {
        index: 2, // put away tab
        gridSortModel: [{ field: 'id', sort: 'desc' }]
      },
      {
        index: 3, // approved tab
        gridSortModel: [{ field: 'id', sort: 'desc' }]
      }
    ],
    selectedSite: SiteID.ALL,
    availableSites: []
  }
  const [state, dispatch] = React.useReducer(receivableOrdersReducer, initialProps)
  return (
    <ReceivableOrdersStateContext.Provider value={state}>
      <ReceivableOrdersDispatchContext.Provider value={dispatch}>{children}</ReceivableOrdersDispatchContext.Provider>
    </ReceivableOrdersStateContext.Provider>
  )
}

function useReceivableOrderState() {
  const context = React.useContext(ReceivableOrdersStateContext)
  if (context === undefined) {
    throw new Error('useReceivableOrderState must be used within a TagProvider')
  }
  return context
}

function useReceivableOrderDispatch() {
  const context = React.useContext(ReceivableOrdersDispatchContext)
  if (context === undefined) {
    throw new Error('useReceivableOrderDispatch must be used within a TagProvider')
  }
  return context
}

function setReceivableOrders(dispatch: Dispatch, receivableOrders?: ReceivableOrder[]) {
  dispatch({ type: 'setReceivableOrders', payload: { receivableOrders } })
}

function resetReceivableOrders(dispatch: Dispatch) {
  dispatch({ type: 'resetReceivableOrders' })
}

export function setIsLoading(dispatch: Dispatch, value: boolean) {
  dispatch({ type: 'setIsLoading', payload: { value } })
}

export function setPullOrderAgain(dispatch: Dispatch, value: boolean) {
  dispatch({ type: 'setPullOrderAgain', payload: { value } })
}

export function setProviderLocalHandlingTime(dispatch: Dispatch, value: number) {
  dispatch({ type: 'setProviderLocalHandlingTime', payload: { value } })
}

export function resetProviderLocalHandlingTime(dispatch: Dispatch) {
  dispatch({ type: 'resetProviderLocalHandlingTime' })
}

export function setActiveTab(dispatch: Dispatch, value: number) {
  dispatch({ type: 'setActiveTab', payload: { value } })
}

export function setReceivableOrdersPresistTab(dispatch: Dispatch, receivableOrders?: ReceivableOrder[], inActiveTab?: number) {
  dispatch({ type: 'setReceivableOrdersPresistTab', payload: { receivableOrders, inActiveTab } })
}

export function setSearchOrderByClientKeyword(dispatch: Dispatch, keyword: string) {
  dispatch({ type: 'setSearchOrderByClientKeyword', payload: { keyword } })
}

export function setGridFilterModel(dispatch: Dispatch, gridFilterModel: GridFilterModel) {
  dispatch({ type: 'setGridFilterModel', payload: { gridFilterModel } })
}

export function setGridSortModel(dispatch: Dispatch, gridSortModel: GridSortModel, index: number) {
  dispatch({ type: 'setGridSortModel', payload: { gridSortModel, index } })
}

export function setSelectedSite(dispatch: Dispatch, selectedSite: SiteID) {
  dispatch({ type: 'setSelectedSite', payload: { selectedSite } })
}

export function setAvailableSites(dispatch: Dispatch, availableSites: Site[]) {
  dispatch({ type: 'setAvailableSites', payload: { availableSites } })
}

export { ReceivableOrdersProvider, resetReceivableOrders, setReceivableOrders, useReceivableOrderDispatch, useReceivableOrderState }
