import { Badge, Grid, Tab, TabProps, Tabs, styled } from '@mui/material'
import { FC, SyntheticEvent, useState } from 'react'
import { colors as qmColors } from '../../theme/colorPalette'
import {
  resetReceivableOrders,
  setActiveTab,
  setIsLoading,
  setReceivableOrders,
  setSearchOrderByClientKeyword,
  useReceivableOrderDispatch,
  useReceivableOrderState
} from '../../providers/ReceivableOrdersProvider'
import SiteSelector from './SiteSelector'
import SearchBar from '../../components/SearchBar'
import ReceivableOrderGrid from './DataGrid'
import { ReceivableStatus } from '../../models/ReceivableStatus'
import { GetReceivableOrders } from '../../graphQL/ReceivableOrder'

const StyledTab = styled(Tab)<TabProps>(({ theme }) => ({
  border: '2px solid',
  borderRadius: '3px 3px 0 0',
  borderEndStartRadius: '0px',
  borderEndEndRadius: '0px',
  fontWeight: 700,
  marginRight: '5px',
  width: '207px',
  color: 'white',
  borderColor: qmColors.qmBlue60,
  backgroundColor: qmColors.qmBlue60,
  '&.Mui-selected': {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  textTransform: 'none'
}))

interface BadgeTabProps extends TabProps {
  badgeValue: number
  testid: string
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <>{children}</>}
    </div>
  )
}

const BadgeTab: FC<BadgeTabProps> = (props) => {
  const { badgeValue, testid, ...extraProps } = props
  return (
    <Badge
      badgeContent={<div data-testid={`${testid}-indicator`}>{badgeValue}</div>}
      invisible={badgeValue === 0}
      color="error"
      slotProps={{
        badge: {
          style: {
            right: '12px',
            overflow: 'visible'
          }
        }
      }}
    >
      <StyledTab data-testid={testid} {...extraProps}></StyledTab>
    </Badge>
  )
}

const ReceivableOrderTabs: FC = () => {
  const { activeTab, receivableOrders, searchOrderByClientKeyword } = useReceivableOrderState()
  const [value, setValue] = useState(activeTab !== undefined ? activeTab : 0)

  const dispatch = useReceivableOrderDispatch()
  const onChange = (query: string) => setSearchOrderByClientKeyword(dispatch, query)
  const onCancelSearch = () => setSearchOrderByClientKeyword(dispatch, '')

  function handleChange(event: SyntheticEvent<Element, Event>, value: any): void {
    setActiveTab(dispatch, value)
    setValue(value)

    resetReceivableOrders(dispatch)
    setIsLoading(dispatch, true)

    async function fetchReceivableOrders() {
      const receivableOrders = await GetReceivableOrders(value + 1)
      setReceivableOrders(dispatch, receivableOrders)
      setIsLoading(dispatch, false)
    }
    fetchReceivableOrders()
  }

  const countInProgressNumber = (receivableOrders || []).filter((receivableOrder) => receivableOrder.Status === ReceivableStatus.COUNT_IN_PROGRESS).length
  const putawayNumber = (receivableOrders || []).filter((receivableOrder) => receivableOrder.Status === ReceivableStatus.PUTAWAY_IN_PROGRESS).length

  return (
    <div>
      <Grid container justifyContent="space-between" sx={{ paddingTop: '18px' }}>
        <Grid item>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs"
            TabIndicatorProps={{
              style: {
                display: 'none'
              }
            }}
            sx={{
              overflow: 'visible',
              div: {
                overflow: 'visible !important'
              }
            }}
          >
            <StyledTab label="Await Stock" data-testid="awaiting-tab" />
            <BadgeTab badgeValue={countInProgressNumber} label="Count in Progress" testid="count-tab" />
            <BadgeTab badgeValue={putawayNumber} label="Putaway" testid="putaway-tab" />
            <StyledTab label="Approved" data-testid="approved-tab" />
          </Tabs>
        </Grid>
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          <SiteSelector sx={(theme) => ({ paddingRight: '15px', height: theme.spacing(5) })} />
          <SearchBar
            value={searchOrderByClientKeyword}
            onChange={onChange}
            onCancelSearch={onCancelSearch}
            placeholder="Filter by keyword"
            data-testid="search-bar"
            sx={(theme) => ({ paddingRight: '15px', height: theme.spacing(5) })}
          />
        </Grid>
      </Grid>
      <TabPanel value={value} index={value}>
        <ReceivableOrderGrid index={value} />
      </TabPanel>
    </div>
  )
}

export default ReceivableOrderTabs
