import { TextField, Tooltip, Autocomplete } from '@mui/material'
import React from 'react'
import { Receivable } from '../../../graphQL/ReceivablesForm'
import { setTableFields, TableFieldProps, useReceivablesFormDispatch, useReceivablesFormState } from '../../../providers/ReceivablesFormProvider'

interface Props {
  receivableId: number
  disabled: boolean
}

export default function Locations({ receivableId, disabled }: Props) {
  const dispatch = useReceivablesFormDispatch()
  const { receivablesFormCopy } = useReceivablesFormState()
  const [receivableItem, setReceivableItem] = React.useState<Receivable | undefined>(undefined)
  const [currentLocation, setCurrentLocation] = React.useState<string>('')
  const [locations, setLocations] = React.useState<string[]>([])

  React.useEffect(() => {
    if (receivablesFormCopy?.items) {
      const targetItem = receivablesFormCopy.items.find(rec => rec.ReceivableID === receivableId)
      setReceivableItem(targetItem)
    }
  }, [receivablesFormCopy?.items, receivableId])

  React.useEffect(() => {
    if (receivableItem) {
      setCurrentLocation(receivableItem.Storage[0]?.Location || '')
      setLocations(receivableItem.Locations.map(location => location.Name))
    }
  }, [receivableItem])

  const [showToolTip, setShowTooltip] = React.useState(false)

  // Checks length/regex of string to see if it is valid
  React.useEffect(() => {
    if (
      (currentLocation.length === 14 && currentLocation.match('[Q][M][1-9][-][0-9][0-9][-][0-9][0-9][-][0-9][0-9][-][A-Z]')) ||
      (currentLocation.length === 12 && currentLocation.match('[Q][M][1-9][-][0-9][0-9][-][0-9][0-9][-][0-9][0-9]'))
    ) {
      setShowTooltip(false)
    } else {
      setShowTooltip(true)
    }
  }, [currentLocation])

  return React.useMemo(() => (
    <>
      {disabled
        ? <>{currentLocation}</>
        : <Autocomplete
          onClick={() => { }}
          freeSolo
          options={locations}
          disableClearable
          forcePopupIcon={false}
          value={currentLocation}
          inputValue={currentLocation}
          onInputChange={(e, newValue) => {
            setCurrentLocation(newValue)
            const newField: TableFieldProps = {
              name: `${receivableId}-Location`,
              value: newValue,
              checked: false
            }
            setTableFields(dispatch, newField)
          }}
          data-testid={`location-ac-${receivableId}`}
          disabled={disabled}
          getOptionDisabled={(option) => option !== currentLocation && disabled}
          renderInput={(params) =>
            <div>
              <Tooltip title={showToolTip ? 'Location should be of either format below: \nQM1-99-99-99 \nQM1-99-99-99-A' : ''} placement="top" style={{ fontSize: 20 }} data-testid={'location-tooltip'} slotProps={{
                tooltip: {
                  sx: (theme) => ({
                    whiteSpace: 'pre-line',
                    fontSize: '20px',
                    [theme.breakpoints.down('lg')]: {
                      fontSize: '16px'
                    }
                  })
                }
              }}>
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    sx: (theme) => ({
                      textAlign: 'center',
                      [theme.breakpoints.up('md')]: {
                        fontSize: '16px',
                        padding: '10px 14px 10px 14px'
                      },
                      [theme.breakpoints.down('lg')]: {
                        fontSize: '0.9rem',
                        padding: '8.5px'
                      },
                      [theme.breakpoints.down('md')]: {
                        fontSize: '0.72rem',
                        padding: '8.5px'
                      }
                    })
                  }}
                />
              </Tooltip>
            </div>
          }
        />
      }
    </>
  ), [dispatch, receivableId, locations, showToolTip, currentLocation, disabled])
}
