import { IconButton, InputAdornment, OutlinedInput, Paper } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import React, { ChangeEvent, FC, useState } from 'react'
import { Clear, Search } from '@mui/icons-material'

interface Props {
  className?: string
  disabled?: boolean
  onCancelSearch?: () => void
  onChange?: (value: string) => void
  value?: string
  placeholder?: string
  sx?: SxProps<Theme>
}

const SearchBar: FC<Props> = ({ value, onChange, onCancelSearch, disabled, placeholder, ...props }) => {
  const [newValue, setNewValue] = useState(value)

  const handleInput = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNewValue(e.target.value)
    if (onChange) {
      onChange(e.target.value)
    }
  }

  const handleCancel = () => {
    setNewValue('')
    if (onCancelSearch) {
      onCancelSearch()
    }
  }

  return (
    <Paper {...props}>
      <OutlinedInput
        value={newValue}
        sx={{ height: '100%' }}
        onChange={handleInput}
        fullWidth
        disabled={disabled}
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position="end">
            {newValue
              ? (
              <IconButton size="small" onClick={handleCancel} disabled={disabled} edge="end">
                <Clear />
              </IconButton>
                )
              : (
              <IconButton size="small" disabled={disabled} edge="end">
                <Search />
              </IconButton>
                )}
          </InputAdornment>
        }
      ></OutlinedInput>
    </Paper>
  )
}

export default SearchBar
