import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  CircularProgress,
  TextField,
  styled
} from '@mui/material'
import { Close, EditOutlined } from '@mui/icons-material'
import React from 'react'
import { editComment } from '../../../graphQL/ReceivablesComments'
import {
  setSnackbarNotification,
  useReceivablesFormDispatch,
  editCommentProvider,
  useReceivablesFormState
} from '../../../providers/ReceivablesFormProvider'
import { colors as qmColors } from '../../../theme/colorPalette'

interface Props {
  receivableOrderCommentId: number
  currentComment: string
}

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: '#FFFFFF'
}))

export default function EditComment({ receivableOrderCommentId, currentComment }: Props) {
  const dispatch = useReceivablesFormDispatch()
  const { openNoteDialog } = useReceivablesFormState()
  const [isLoading, setIsLoading] = React.useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [comment, setComment] = React.useState(currentComment)

  React.useEffect(() => {
    setComment(currentComment)
  }, [currentComment])

  const closeDialog = () => {
    setOpenDialog(false)
  }

  const onConfirm = React.useCallback(async () => {
    try {
      setIsLoading(true)
      await editComment(receivableOrderCommentId, comment)
      editCommentProvider(dispatch, {
        receivableOrderCommentId,
        comment
      })
      setIsLoading(false)
      setSnackbarNotification(dispatch, true, 'success', 'Comment Updated')
    } catch (e) {
      setSnackbarNotification(dispatch, true, 'error', 'Error updating comment')
    }
    closeDialog()
  }, [comment, dispatch, receivableOrderCommentId])

  const DialogTitle = React.useCallback((props: any) => {
    const { children, ...other } = props
    return (
      <MuiDialogTitle {...other} sx={{ background: qmColors.qmBlue60, marginBottom: '10px' }}>
        <Typography color="#FFFFFF" fontSize="18px" fontWeight="600">
          {children}
        </Typography>
        <StyledCloseButton aria-label="close" onClick={closeDialog} size="large">
          <Close />
        </StyledCloseButton>
      </MuiDialogTitle>
    )
  }, [])

  return React.useMemo(
    () => (
      <>
        <IconButton
          onClick={() => setOpenDialog(true)}
          sx={{ color: qmColors.green }}
          disabled={openNoteDialog}
          data-testid={`edit-comment-button-${receivableOrderCommentId}`}
          size="large"
        >
          <EditOutlined />
        </IconButton>
        <Dialog maxWidth="lg" scroll="paper" open={openDialog} onClose={closeDialog}>
          <DialogTitle>Update Comment</DialogTitle>
          <DialogContent sx={{ minWidth: '600px', padding: '8px 15px !important', display: 'flex', justifyContent: 'center' }}>
            {isLoading && <CircularProgress />}
            {!isLoading && (
              <TextField
                label="Message"
                fullWidth
                InputLabelProps={{ shrink: true, disabled: true }}
                variant="outlined"
                multiline={true}
                minRows={10}
                value={comment}
                onChange={(event) => setComment(event.target.value)}
                data-testid="edit-comment-textfield"
              />
            )}
          </DialogContent>
          <DialogActions sx={{ margin: '0 7px' }}>
            {!isLoading && (
              <>
                <Button onClick={closeDialog} variant="outlined" data-testid="edit-comment-cancel-button">
                  Cancel
                </Button>
                <Button onClick={onConfirm} variant="contained" data-testid="edit-comment-update-button">
                  Update
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </>
    ),
    [DialogTitle, comment, isLoading, onConfirm, openDialog, openNoteDialog, receivableOrderCommentId]
  )
}
