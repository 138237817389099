import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  CircularProgress
} from '@mui/material'
import { Close, DeleteOutlined } from '@mui/icons-material'
import React from 'react'
import { submitDeleteHandlingTime } from '../../../graphQL/ReceivablesHandlingTime'
import { deleteHandlingTime, setSnackbarNotification, useReceivablesFormDispatch } from '../../../providers/ReceivablesFormProvider'
import { colors as qmColors } from '../../../theme/colorPalette'

interface Props {
  handlingTimeId: string
  currentHandlingTime: number
}

export default function UpdateHandlingTime({ handlingTimeId, currentHandlingTime }: Props) {
  const dispatch = useReceivablesFormDispatch()
  const [isLoading, setIsLoading] = React.useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)

  const closeDialog = () => {
    setOpenDialog(false)
  }

  const onConfirm = async () => {
    try {
      setIsLoading(true)
      await submitDeleteHandlingTime(parseInt(handlingTimeId))
      deleteHandlingTime(dispatch, parseInt(handlingTimeId))
      setIsLoading(false)
      setSnackbarNotification(dispatch, true, 'success', 'Handling Time Updated')
    } catch (e) {
      setSnackbarNotification(dispatch, true, 'error', 'Error deleting handling time')
    }
    closeDialog()
  }

  const DialogTitle = (props: any) => {
    const { children, ...other } = props
    return (
      <MuiDialogTitle {...other} sx={{ background: qmColors.qmBlue60, marginBottom: '10px' }}>
        <Typography sx={{ color: '#FFFFFF', fontSize: '18px', fontWeight: 600 }}>{children}</Typography>
        <IconButton
          aria-label="close"
          sx={(theme) => ({ position: 'absolute', right: theme.spacing(1), top: theme.spacing(1), color: '#FFFFFF' })}
          onClick={closeDialog}
          size="large"
        >
          <Close />
        </IconButton>
      </MuiDialogTitle>
    )
  }

  return (
    <>
      <IconButton onClick={() => setOpenDialog(true)} size="large">
        <DeleteOutlined sx={{ color: qmColors.green }} />
      </IconButton>
      <Dialog maxWidth="lg" scroll="paper" open={openDialog} onClose={closeDialog}>
        <DialogTitle>Delete time</DialogTitle>
        <DialogContent
          sx={{
            minWidth: '200px',
            padding: '8px 15px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {isLoading && <CircularProgress />}
          {!isLoading && <>Are you sure you would like to delete the handling time of {currentHandlingTime} minutes?</>}
        </DialogContent>
        <DialogActions sx={{ margin: '0 7px' }}>
          {!isLoading && (
            <>
              <Button onClick={closeDialog} variant="outlined">
                Cancel
              </Button>
              <Button onClick={onConfirm} variant="contained">
                Confirm
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}
