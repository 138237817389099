import moment from 'moment'

const INVALID_DATE = '0000-00-00'

enum DATE_FORMAT {
  QM_DATE_FORMAT = 'YYYY-MM-DD',
  WAM_DATE_FORMAT = 'DD/MM/YY'
}

enum DATE_STATE {
  PAST,
  TODAY,
  FUTURE
}

// quickmail api send date string in YYYY-MM-DD format
const wamDateStringFormat = (qmDateString: string | undefined | null) => {
  if (qmDateString === INVALID_DATE || !qmDateString) {
    return 'N/A'
  }
  const qmMoment = moment(qmDateString, DATE_FORMAT.QM_DATE_FORMAT)
  return qmMoment.format(DATE_FORMAT.WAM_DATE_FORMAT)
}

const qmNowDateString = () => {
  return customNowDateString(DATE_FORMAT.QM_DATE_FORMAT)
}

const customNowDateString = (customFormat: string) => {
  return moment().format(customFormat)
}

const checkDateState = (dateString: string, dateFormat: string = DATE_FORMAT.QM_DATE_FORMAT) => {
  const qmMoment = moment(dateString, dateFormat)
  const now = moment()
  if (qmMoment.isSame(now, 'date')) {
    return DATE_STATE.TODAY
  }
  if (now > qmMoment) {
    return DATE_STATE.PAST
  }
  return DATE_STATE.FUTURE
}

export { wamDateStringFormat, qmNowDateString, customNowDateString, checkDateState, DATE_STATE, DATE_FORMAT }
