import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, SxProps, Theme } from '@mui/material'
import React, { FC, useCallback } from 'react'
import { setSelectedSite, useReceivableOrderDispatch, useReceivableOrderState } from '../../../providers/ReceivableOrdersProvider'
import { SiteID } from '../../../models/Site'

interface Props {
  sx?: SxProps<Theme>
}

const SiteSelector: FC<Props> = ({ ...props }) => {
  const dispatch = useReceivableOrderDispatch()
  const { selectedSite, availableSites } = useReceivableOrderState()

  const onChange = useCallback((event: SelectChangeEvent<SiteID>) => {
    setSelectedSite(dispatch, event.target.value as SiteID)
  }, [dispatch])

  return (
    <FormControl {...props} variant="outlined">
      <InputLabel>Site</InputLabel>
      <Select label="Select Site" value={selectedSite} onChange={onChange} sx={{ height: '100%' }}>
        <MenuItem key={0} value={0}>
          ALL
        </MenuItem>
        {availableSites.map(item => (
          <MenuItem key={item.SiteID} value={item.SiteID}>
            {item.SiteName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SiteSelector
