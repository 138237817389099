import React from 'react'
import { GetReceivableOrders } from '../../graphQL/ReceivableOrder'
import { setAvailableSites, setIsLoading, setReceivableOrdersPresistTab, useReceivableOrderDispatch, useReceivableOrderState } from '../../providers/ReceivableOrdersProvider'
import { useReceivablesFormDispatch, setReceivablesForm } from '../../providers/ReceivablesFormProvider'
import ReceivableOrderTabs from './Tabs'
import { GetSites } from '../../graphQL/Sites'
import { useUserState } from '../../providers/UserProvider'

function ReceivableOrders() {
  const state = useReceivableOrderState()
  const dispatch = useReceivableOrderDispatch()
  const formDispatch = useReceivablesFormDispatch()
  const { userEmail } = useUserState()

  async function fetchReceivableOrders() {
    setIsLoading(dispatch, true)
    const activeTabIndex = state.activeTab !== undefined ? state.activeTab : 0
    const receivableOrders = await GetReceivableOrders(activeTabIndex + 1)
    setReceivableOrdersPresistTab(dispatch, receivableOrders, activeTabIndex)
    setIsLoading(dispatch, false)
  }

  React.useEffect(() => {
    setReceivablesForm(formDispatch, undefined)
    if (state.receivableOrders === undefined) {
      fetchReceivableOrders()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    async function fetchAvailableSites() {
      setIsLoading(dispatch, true)
      const availableSites = await GetSites(userEmail || '')
      setAvailableSites(dispatch, availableSites)
      setIsLoading(dispatch, false)
    }
    if (state.availableSites.length === 0) {
      fetchAvailableSites()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ padding: '0 40px' }}>
      <ReceivableOrderTabs />
    </div>
  )
}

export default ReceivableOrders
