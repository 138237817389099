import { API, graphqlOperation } from 'aws-amplify'
import { ReceivableStatus } from '../models/ReceivableStatus'
import { Comments } from './ReceivablesComments'
import { HandlingTime } from './ReceivablesHandlingTime'
import { Photo } from './ReceivablesPhotos'

export interface ReceivableForm {
  order: ReceivableOrder
  items: Receivable[]
  comments: Comments[]
  photos: Photo[]
  handling: HandlingTime[]
}

export interface ReceivableOrder {
  ReceivableOrderID: number
  ExpectedDate: string
  CustName: string
  CustEmail: string
  Supplier?: string
  UserEmail: string
  EmailSenderID?: number | null
  EmailSentTime?: string | null
  ReceivedDate?: string | null
  CountedDate?: string | null
  Reference?: string
  Comment?: string
  Status?: number
  ContainerSize?: number
}

export interface Location {
  Name: string
}

export interface Receivable {
  ActualQty: number
  CustItemID: string
  ItemID: number
  Quantity: number
  ItemName: string
  ReceivableID: number
  Status: ReceivableStatus
  UnitCount: number | undefined
  UnitID: number | undefined
  CountingTime?: number
  ReceivedDate?: string
  ReceivedBy?: string
  CountedDate?: string
  CountedBy?: string
  Storage: Storage[]
  Locations: Location[]
  ItemDescription: string
}

export interface Storage {
  StorageID?: number
  Location?: string
  ItemID: number
  ReceivableID: number
  Quantity: number
  Batch?: string
  Expiry?: string | null
}

interface ReceivableFormQueryResult {
  data: {
    getReceivableForm: ReceivableForm
  }
}

const getReceivablesFormQuery = `query getReceivableForm($receivableOrderID: Int) {
  getReceivableForm(receivableOrderID: $receivableOrderID) {
    comments {
      Comment
      CreatedTime
      ReceivableOrderCommentID
    }
    items {
      ActualQty
      CountingTime
      CustItemID
      ItemDescription
      ItemID
      Quantity
      ItemName
      ReceivableID
      Status
      UnitCount
      UnitID
      ReceivedDate
      ReceivedBy
      CountedDate
      CountedBy
      Storage {
        Batch
        Expiry
        ItemID
        Location
        Quantity
        ReceivableID
        StorageID
      }
      Locations {
        Name
      }
    }
    order {
      CustName
      ExpectedDate
      ReceivedDate
      CountedDate
      ReceivableOrderID
      Comment
      CustEmail
      UserEmail
      EmailSentTime
      EmailSenderID
      ContainerSize
      Status
    }
    photos {
      ReceivableOrderPhotoID
      ReceivableOrderID
      Path
      UploadedDate
      UploadedBy
      FileName
    }
    handling {
      ReceivableOrderHandlingID
      HandlingDate
      HandlingTime
    }
  }
}`

export const GetReceivablesForm = async (receivableOrderID: number): Promise<ReceivableForm | undefined> => {
  try {
    const result = (await API.graphql(graphqlOperation(getReceivablesFormQuery, { receivableOrderID }))) as ReceivableFormQueryResult
    if (result.data.getReceivableForm) {
      return result.data.getReceivableForm as ReceivableForm
    }
  } catch (e) {
    console.log(e) // TODO - Add notification to errors
  }
  return undefined
}

export interface ReceivableFormInput {
  order: ReceivableOrderInput
  items: ReceivableInput[]
}

export interface ReceivableOrderInput {
  ReceivableOrderID: number
  ExpectedDate: string
  CustName: string
  CustEmail: string
  Supplier?: string
  UserEmail: string
  EmailSenderID?: number | null
  EmailSentTime?: string | null
  ReceivedDate?: string | null
  CountedDate?: string | null
  Reference?: string
  Comment?: string
  Status?: number
  ContainerSize?: number
}

export interface LocationInput {
  Name: string
}

export interface ReceivableInput {
  ActualQty: number
  CountingTime: number
  CustItemID: string
  ItemID: number
  Quantity: number
  ItemName: string
  ReceivableID: number
  Status: 1 | 2 | 3
  UnitCount: number | undefined
  UnitID: number | undefined
  ReceivedDate: string
  CountedDate: string
  ReceivedBy: string
  Storage: Storage[]
  Locations: Location[]
}

export interface StorageInput {
  StorageID: number
  Location: string
  ItemID: number
  ReceivableID: number
  Quantity: number
  Batch: string
  Expiry: string
}

/*
const updateReceivableFormV2 = gql`
  mutation updateReceivables($receivableFormInput: ReceivableFormInput) {
    updateReceivables(receivableFormInput: $receivableFormInput)
  }`
  */
const updateReceivableFormV2 = `mutation updateReceivables($receivableFormInput: ReceivableFormInput){
    updateReceivables(receivableFormInput: $receivableFormInput)
  }`

export const UpdateReceivableForm = async (receivableFormInput: ReceivableFormInput) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(updateReceivableFormV2, {
        receivableFormInput
      })
    )) as {
      data: {
        updateReceivables: string
      }
    }
    return response.data.updateReceivables
  } catch {
    return 'Error'
  }
}
