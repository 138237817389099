import { API, graphqlOperation } from 'aws-amplify'

const sendEmailMutation = `mutation sendEmail($emailInput: EmailInput!) {
    sendEmail(emailInput: $emailInput) {
      emailSentTime
      emailSenderID
    }
}
`

interface EmailOrderInfo {
  ReceivableOrderID: number
  ExpectedDate: string
  CountedDate: string
  CustName: string
  CustEmail: string
  UserEmail: string
}

interface EmailReceivableItem {
  CustItemID: string
  ItemID: string
  ItemName: string
  Quantity: number
  ActualQty: number
  UnitID?: number
  UnitCount?: number
}

interface PhotoInput {
  Path: string
  UploadedDate: string
  FileName: string
}

interface EmailInput {
  items: EmailReceivableItem[]
  order: EmailOrderInfo
  comment: string
  emailSenderID: number
  photos: PhotoInput[]
}

export interface EmailSent {
  emailSentTime: string
  emailSenderID: string
}

interface SendEmailResponse {
  data: {
    sendEmail: EmailSent
  }
}

export const sendEmail = async (emailInput: EmailInput): Promise<EmailSent | undefined> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(sendEmailMutation, { emailInput })
    )) as SendEmailResponse
    return result.data.sendEmail
  } catch (e) {
    console.log(e) // TODO - Add notification to errors
  }
}
