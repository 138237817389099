import { Snackbar, IconButton, Grid, Alert, styled, Box } from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import { FC, useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { GetReceivablesForm } from '../../graphQL/ReceivablesForm'
import { setPullOrderAgain, useReceivableOrderDispatch, useReceivableOrderState } from '../../providers/ReceivableOrdersProvider'
import {
  loadUnsavedForm,
  setIsLoading,
  setReceivablesForm,
  setSnackbarNotification,
  setUserEmail,
  useReceivablesFormDispatch,
  useReceivablesFormState
} from '../../providers/ReceivablesFormProvider'
import Backdrops from './Backdrops'
import Comments from './Comments'
import AddHandlingTime from './AddHandlingTime'
import Header from './Header'
import Photos from './Photos'
import FormTable from './Table'
import { redirectToLogin } from '../../aws-exports'
import { colors as qmColors } from '../../theme/colorPalette'
import { Auth } from 'aws-amplify'

interface RouteParams {
  ReceivalId: string
}

const StyledAlert = styled(Alert)(() => ({
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  '& svg': {
    color: 'white'
  },
  fontWeight: 600
}))

const StyledSnackbar = styled(Snackbar)(() => ({
  width: '100vw',
  height: '85px',
  top: '0 !important',
  '.MuiAlert-filledSuccess': {
    backgroundColor: qmColors.successGreen
  },
  '.MuiAlert-filledError': {
    backgroundColor: qmColors.errorRed
  },
  '.MuiAlert-filledInfo': {
    backgroundColor: qmColors.infoBlue
  },
  '.MuiAlert-filledWarning': {
    backgroundColor: qmColors.qmOrange
  }
}))

const BackButton: FC = () => {
  const history = useHistory()
  return (
    <div style={{ position: 'absolute', left: '15px', top: '6px' }}>
      <IconButton onClick={() => history.push('/')} size="large">
        <ArrowBackIos sx={(theme) => ({ color: theme.palette.primary.main })} />
      </IconButton>
    </div>
  )
}

const ReceivablesForm: FC = () => {
  const { ReceivalId } = useParams<RouteParams>()
  const state = useReceivablesFormState()
  const { isLoading, snackbarNotification, showEmailSentNotification, receivablesForm, userEmail } = state
  const orderState = useReceivableOrderState()
  const dispatch = useReceivablesFormDispatch()
  const orderDispatch = useReceivableOrderDispatch()

  const getUserEmail = useCallback(async () => {
    try {
      const { attributes } = await Auth.currentUserInfo()
      setUserEmail(dispatch, attributes.email)
    } catch {
      redirectToLogin()
    }
  }, [dispatch])

  useEffect(() => {
    if (!userEmail) {
      getUserEmail()
    }
  }, [getUserEmail, userEmail])

  useEffect(() => {
    const fetchReceivablesForm = async () => {
      if (!state.receivablesForm || orderState.pullOrderAgain) {
        setIsLoading(dispatch, true)
        const receivablesForm = await GetReceivablesForm(Number(ReceivalId))
        if (receivablesForm) {
          setReceivablesForm(dispatch, receivablesForm)
          loadUnsavedForm(dispatch)
        } else {
          setSnackbarNotification(dispatch, true, 'error', 'Unable to load receivable')
        }
        setIsLoading(dispatch, false)

        setPullOrderAgain(orderDispatch, false)
      }
    }
    fetchReceivablesForm()
  }, [ReceivalId, dispatch, orderDispatch, orderState.pullOrderAgain, state.receivablesForm])

  const closeNotification = () => setSnackbarNotification(dispatch, false, undefined, undefined)

  return (
    <>
      <Backdrops isLoading={isLoading} emailSentNotification={showEmailSentNotification} />
      <StyledSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={closeNotification}
        open={snackbarNotification!.open}
        autoHideDuration={2000}
      >
        <StyledAlert variant="filled" severity={snackbarNotification!.severity || 'info'}>
          {snackbarNotification!.message ? snackbarNotification!.message : 'message'}
        </StyledAlert>
      </StyledSnackbar>
      {!isLoading && !showEmailSentNotification && receivablesForm && (
        <Box
          sx={(theme) => ({
            maxHeight: 'calc(100vh - 80px)',
            [theme.breakpoints.down('sm')]: {
              maxHeight: 'calc(100vh - 160px)'
            },
            '& .MuiIconButton-root': {
              padding: '6px',
              marginLeft: '-10px'
            },
            width: '100%',
            padding: '0 40px',
            position: 'relative'
          })}
        >
          <BackButton />
          <Header />
          <Photos photos={receivablesForm?.photos || []} receivableOrderID={receivablesForm?.order.ReceivableOrderID} />
          <FormTable />
          <Grid container spacing={3} paddingBottom="60px">
            <Comments />
            <AddHandlingTime />
          </Grid>
        </Box>
      )}
    </>
  )
}

export default ReceivablesForm
