import { Components, Theme } from '@mui/material/styles'
import { colors } from './colorPalette'

export const overrides: Components<Omit<Theme, 'components'>> = {
  MuiButton: {
    styleOverrides: {
      contained: {
        backgroundColor: colors.green,
        color: '#FFFFFF',
        textTransform: 'none',
        fontSize: '18px',
        fontWeight: 600,
        '&:hover': {
          backgroundColor: colors.grey30,
          color: colors.grey50
        },
        '& span': {
          margin: '0 5px'
        }
      },
      outlined: {
        textTransform: 'none',
        fontSize: '18px',
        fontWeight: 600,
        backgroundColor: '#FFFFFF',
        border: `2px solid ${colors.green}`,
        color: colors.green,
        '&:hover': {
          border: `2px solid ${colors.grey40}`,
          color: colors.grey40
        },
        '& span': {
          margin: '0 5px'
        }
      }
    }
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        backgroundColor: '#FFFFFF'
      }
    }
  }
}
