import { AppBar, Grid, Toolbar, Typography, styled } from '@mui/material'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import logo from '../../assets/QuickmailLogo.png'
import { useReceivablesFormState } from '../../providers/ReceivablesFormProvider'
import { setUserEmail, useUserDispatch, useUserState } from '../../providers/UserProvider'
import { redirectToLogin } from '../../aws-exports'
import UserExpandMoreButton from './UserExpandMoreButton'
import ReceivableMenuButtons from './ReceivableMenuButtons'

function Header() {
  const history = useHistory()
  const userDispatch = useUserDispatch()
  const { userEmail } = useUserState()
  const { receivablesForm } = useReceivablesFormState()
  const [showReceivableMenu, setShowRceivableMenu] = React.useState(false)

  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    background: '#FFFFFF',
    padding: `${showReceivableMenu ? '0px 0px 0px 40px' : '0px 40px'}`,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    height: '67px',
    display: 'flex',
    justifyContent: 'space-between'
  }))

  React.useEffect(() => {
    if (receivablesForm) {
      setShowRceivableMenu(true)
    } else {
      setShowRceivableMenu(false)
    }
  }, [receivablesForm])

  React.useEffect(() => {
    const checkAuth = async () => {
      try {
        const { attributes } = await Auth.currentUserInfo()
        setUserEmail(userDispatch, attributes.email)
      } catch {
        redirectToLogin()
      }
    }
    if (!userEmail) {
      checkAuth()
    }
  }, [userEmail, userDispatch])

  const redirectHome = React.useCallback(() => {
    history.push('/')
  }, [history])

  return (
    <AppBar position="fixed">
      <StyledToolbar disableGutters>
        <Grid item>
          <Link to="/">
            <img
              src={logo}
              style={{
                height: '40px',
                marginLeft: '-5px'
              }}
              alt="logo"
              onClick={redirectHome}
            />
          </Link>
        </Grid>
        <Grid item sx={{ height: '100%', display: 'flex' }} alignItems="center">
          <Grid container alignItems="center" sx={{ height: '100%' }}>
            <Typography
              sx={(theme) => ({
                color: theme.palette.primary.main,
                fontWeight: 600
              })}
            >
              {userEmail}
            </Typography>
            <UserExpandMoreButton />
            {showReceivableMenu ? <ReceivableMenuButtons /> : null}
          </Grid>
        </Grid>
      </StyledToolbar>
    </AppBar>
  )
}

export default Header
