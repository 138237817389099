import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material'
import { Close, EditOutlined } from '@mui/icons-material'
import React from 'react'
import { submitUpdateHandlingTime } from '../../../graphQL/ReceivablesHandlingTime'
import { setSnackbarNotification, useReceivablesFormDispatch, updateHandlingTime } from '../../../providers/ReceivablesFormProvider'
import { colors as qmColors } from '../../../theme/colorPalette'
import { qmNowDateString } from '../../../utils'

interface Props {
  handlingTimeId: string
  currentHandlingTime: number
}

export default function UpdateHandlingTime({ handlingTimeId, currentHandlingTime }: Props) {
  const dispatch = useReceivablesFormDispatch()
  const [isLoading, setIsLoading] = React.useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [handlingTime, setHandlingTime] = React.useState(currentHandlingTime)

  React.useEffect(() => {
    setHandlingTime(currentHandlingTime)
  }, [currentHandlingTime])

  function selectTime(event: SelectChangeEvent<number>) {
    setHandlingTime(event.target.value as number)
  }

  const closeDialog = () => {
    setOpenDialog(false)
  }

  const onConfirm = async () => {
    try {
      setIsLoading(true)
      await submitUpdateHandlingTime(parseInt(handlingTimeId), handlingTime)
      updateHandlingTime(dispatch, {
        ReceivableOrderHandlingID: parseInt(handlingTimeId),
        HandlingDate: qmNowDateString(),
        HandlingTime: handlingTime
      })
      setIsLoading(false)
      setSnackbarNotification(dispatch, true, 'success', 'Handling Time Updated')
    } catch (e) {
      setSnackbarNotification(dispatch, true, 'error', 'Error updating handling time')
    }
    closeDialog()
  }

  const DialogTitle = (props: any) => {
    const { children, ...other } = props
    return (
      <MuiDialogTitle {...other} sx={{ background: qmColors.qmBlue60, marginBottom: '10px' }}>
        <Typography sx={{ color: '#FFFFFF', fontSize: '18px', fontWeight: 600 }}>{children}</Typography>
        <IconButton
          aria-label="close"
          sx={(theme) => ({ position: 'absolute', right: theme.spacing(1), top: theme.spacing(1), color: '#FFFFFF' })}
          onClick={closeDialog}
          size="large"
        >
          <Close />
        </IconButton>
      </MuiDialogTitle>
    )
  }

  return (
    <>
      <IconButton onClick={() => setOpenDialog(true)} size="large">
        <EditOutlined sx={{ color: qmColors.green }} />
      </IconButton>
      <Dialog maxWidth="lg" scroll="paper" open={openDialog} onClose={closeDialog}>
        <DialogTitle>Update time</DialogTitle>
        <DialogContent sx={{ minWidth: '200px', padding: '8px 15px', display: 'flex', justifyContent: 'center' }}>
          {isLoading && <CircularProgress />}
          {!isLoading && (
            <FormControl variant="outlined" fullWidth>
              <Select name="countingTime" value={handlingTime} onChange={selectTime} data-testid={'handlingTime-select'}>
                <MenuItem value={0}>0 minutes</MenuItem>
                <MenuItem value={15} data-testid={'15 minutes'}>
                  15 minutes
                </MenuItem>
                <MenuItem value={30}>30 minutes</MenuItem>
                <MenuItem value={45}>45 minutes</MenuItem>
                <MenuItem value={60}>60 minutes</MenuItem>
                <MenuItem value={75}>75 minutes</MenuItem>
                <MenuItem value={90}>90 minutes</MenuItem>
                <MenuItem value={105}>105 minutes</MenuItem>
                <MenuItem value={120}>2 hours</MenuItem>
                <MenuItem value={180}>3 hours</MenuItem>
                <MenuItem value={240}>4 hours</MenuItem>
                <MenuItem value={300}>5 hours</MenuItem>
                <MenuItem value={360}>6 hours</MenuItem>
                <MenuItem value={420}>7 hours</MenuItem>
                <MenuItem value={480}>8 hours</MenuItem>
                <MenuItem value={540}>9 hours</MenuItem>
                <MenuItem value={600}>10 hours</MenuItem>
                <MenuItem value={660}>11 hours</MenuItem>
                <MenuItem value={720} data-testid={'12 hours'}>
                  12 hours
                </MenuItem>
                <MenuItem value={1440}>24 hours</MenuItem>
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions sx={{ margin: '0 7px' }}>
          {!isLoading && (
            <>
              <Button onClick={closeDialog} variant="outlined">
                Cancel
              </Button>
              <Button onClick={onConfirm} variant="contained">
                Update
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}
