export enum ReceivableStatus {
  AWAITING_STOCK = 1, // receivable is not in QM yet
  COUNT_IN_PROGRESS = 2, // QM has the receivable order and has made a start on it
  PUTAWAY_IN_PROGRESS = 3, // QM has counted the item and is ready to shelf/putaway the item
  APPROVED = 4 // QM has shelved the item in the warehouse
}

export const ReceivableStatusKeyLabel: {
  key: number;
  label: string
}[] = [
  { key: ReceivableStatus.AWAITING_STOCK, label: 'Await Stock' },
  { key: ReceivableStatus.COUNT_IN_PROGRESS, label: 'Count in Progress' },
  { key: ReceivableStatus.PUTAWAY_IN_PROGRESS, label: 'Putaway' },
  { key: ReceivableStatus.APPROVED, label: 'Approved' }
]
