import React from 'react'
import { Switch } from 'react-router'
import { useHistory } from 'react-router-dom'
import Header from '../components/Header/Header'
import { ReceivableForm } from '../graphQL/ReceivablesForm'
import PrivateRoute from './privateRoute'
import ReceivableOrder from './ReceivableOrders'
import ReceivablesForm from './ReceivablesForm'

function Routes() {
  const history = useHistory()
  const unsavedForm = localStorage.getItem('receivablesFormCopy')
  React.useEffect(() => {
    if (unsavedForm) {
      const receivableForm: ReceivableForm = JSON.parse(unsavedForm)
      const receivableOrderId = receivableForm.order.ReceivableOrderID
      history.push(`/receivable/${receivableOrderId}`)
    }
  }, [unsavedForm, history])

  return (
    <>
      <Header />
      <div style={{ marginTop: '67px' }}>
        <Switch>
          <PrivateRoute exact path="/" component={ReceivableOrder} />
          <PrivateRoute path="/receivable/:ReceivalId" component={ReceivablesForm} />
        </Switch>
      </div>
    </>
  )
}

export default Routes
