import React from 'react'

type Action =
  | { type: 'setUserEmail'; payload: { email: string } }

export type Dispatch = (action: Action) => void
type State = {
  userEmail?: string
}

type Props = { children: React.ReactNode }

const UserStateContext = React.createContext<State | undefined>(undefined)
const UserDispatchContext = React.createContext<Dispatch | undefined>(undefined)

function userReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setUserEmail': {
      return {
        ...state,
        userEmail: action.payload.email
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action!}`)
    }
  }
}

export function UserProvider({ children }: Props) {
  const initialState = {
    userEmail: undefined
  }
  const [state, dispatch] = React.useReducer(userReducer, initialState)
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}

export function useUserState() {
  const context = React.useContext(UserStateContext)
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider')
  }
  return context
}

export function useUserDispatch() {
  const context = React.useContext(UserDispatchContext)
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider')
  }
  return context
}

export function setUserEmail(dispatch: Dispatch, email: string) {
  dispatch({ type: 'setUserEmail', payload: { email } })
}
