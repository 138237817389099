import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  Box,
  styled
} from '@mui/material'
import { Close } from '@mui/icons-material'
import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import {
  setOpenPrintDialog,
  useReceivablesFormDispatch,
  useReceivablesFormState
} from '../../../providers/ReceivablesFormProvider'
import { colors as qmColors } from '../../../theme/colorPalette'
import { wamDateStringFormat } from '../../../utils'

const StyledContent = styled(DialogContent)({
  margin: '8px 15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  border: `1px solid ${qmColors.qmBlue}`,
  borderRadius: '10px',
  maxWidth: '14.8cm', // A6 width (landscape)
  height: '10.5cm', // A6 height (landscape)
  '@media print': {
    height: '100%',
    width: '100%',
    border: 'none',
    margin: 'auto',
    padding: '1cm'
  }
})

const StyledSkuText = styled(Typography)({
  fontWeight: 700,
  fontSize: '35px',
  lineHeight: '42px',
  color: '#000000',
  '@media print': {
    fontSize: '48px',
    lineHeight: '55px'
  }
})

const StyledTypography = styled(Typography)({
  fontWeight: 400,
  fontSize: '22px',
  lineHeight: '27px',
  color: '#000000',
  '@media print': {
    fontSize: '32px',
    lineHeight: '37px'
  }
})

const ItemDetailsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  margin: '20px 0',
  '& span': {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000',
    '@media print': {
      fontSize: '24px',
      lineHeight: '27px'
    }
  }
})

const PrintReceivableItemDialog = () => {
  const { receivablesFormCopy, openPrintDialog, selectedReceivableID } = useReceivablesFormState()
  const dispatch = useReceivablesFormDispatch()
  const selectedReceivableItem = receivablesFormCopy?.items.find(rec => rec.ReceivableID === selectedReceivableID)
  const closeDialog = () => setOpenPrintDialog(dispatch, false)

  const componentRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  const DialogTitle = (props: any) => {
    const { children, ...other } = props
    return (
      <MuiDialogTitle {...other} sx={{ background: qmColors.qmBlue60, marginBottom: '10px' }}>
        <Typography color='#FFFFFF' fontSize='18px' fontWeight={600}>{children}</Typography>
        <IconButton
          aria-label="close"
          sx={(theme) => ({
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: '#FFFFFF'
          })}
          onClick={closeDialog}
          size="large">
          <Close />
        </IconButton>
      </MuiDialogTitle>
    )
  }

  const location = selectedReceivableItem?.Storage[0]?.Location
  const batchNo = selectedReceivableItem?.Storage[0]?.Batch
  const expiryDate = selectedReceivableItem?.Storage[0]?.Expiry
  const hasItemDetails = batchNo || expiryDate

  return (
    <>
      {openPrintDialog
        ? <Dialog
          PaperProps={{ sx: { minWidth: '420px' } }}
          maxWidth="lg"
          scroll="paper"
          open={openPrintDialog}
          onClose={closeDialog}
        >
          <DialogTitle>Label preview</DialogTitle>
          <StyledContent ref={componentRef}>
            <style>
              {`
                html, body {
                  height: 100%;
                }
              `}
            </style>
            <StyledSkuText>{selectedReceivableItem?.CustItemID}</StyledSkuText>
            {location && (
              <StyledTypography>{location}</StyledTypography>
            )}
            <StyledTypography>{selectedReceivableItem?.ItemName}</StyledTypography>
            {hasItemDetails && (
              <ItemDetailsContainer>
                {batchNo ? <span>Batch: {batchNo}</span> : null}
                {expiryDate ? <span>Expiry: {wamDateStringFormat(expiryDate)}</span> : null}
              </ItemDetailsContainer>
            )}
            <Typography fontWeight={400} fontSize='22px' lineHeight='27px' color='#000000' sx={{ '@media print': { fontSize: '32px', lineHeight: '37px' } }}>{receivablesFormCopy?.order.CustName}</Typography>
          </StyledContent>
          <DialogActions sx={{ marginRight: '5px' }}>
            <>
              <Button onClick={closeDialog} variant="outlined">
                Cancel
              </Button>
              <Button onClick={handlePrint} variant="contained">
                Send to Print
              </Button>
            </>
          </DialogActions>
        </Dialog>
        : null}
    </>
  )
}

export default PrintReceivableItemDialog
