import { Backdrop, Box, CircularProgress, Typography, styled } from '@mui/material'
import { FC } from 'react'
import mailIcon from '../../../assets/MailIcon.png'

interface Props {
  isLoading: boolean
  emailSentNotification: boolean
}

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1
}))

const Backdrops:FC<Props> = ({ isLoading, emailSentNotification }) => {
  return (
    <>
      <StyledBackdrop id="loadingBackdrop" open={isLoading}>
        <CircularProgress />
      </StyledBackdrop>
      <StyledBackdrop id="emailSentNotification" open={emailSentNotification}>
        <Box display="inline-block" textAlign="center">
          <Typography color="white" fontSize="2rem" paddingBottom="2rem" fontWeight="bold">Email confirmation</Typography>
          <Typography color="white" fontSize="2rem" paddingBottom="2rem">Your email has been sent</Typography>
          <img src={mailIcon} alt="Email Sent" />
        </Box>
      </StyledBackdrop>
    </>
  )
}

export default Backdrops
