import { Popover, IconButton, styled, Box } from '@mui/material'
import { CloseOutlined, CommentOutlined, MoreHoriz, PhotoCameraOutlined, PrintOutlined } from '@mui/icons-material'
import React from 'react'
import { setOpenNoteDialog, setOpenPrintDialog, setOpenFileSelectDialog, setSelectedReceivableID, useReceivablesFormDispatch, setCommentTextForReceivableId } from '../../../providers/ReceivablesFormProvider'
import { colors as qmColors } from '../../../theme/colorPalette'
import PrintReceivableItemDialog from '../PrintReceivableItem/PrintReceivableItemDialog'

interface Props {
  receivableId: number
}

const StyledIconButton = styled(IconButton)({
  color: '#FFFFFF'
})

export default function MoreAction({ receivableId }: Props) {
  const dispatch = useReceivablesFormDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = React.useCallback((event: any) => {
    setSelectedReceivableID(dispatch, receivableId)
    setAnchorEl(event.currentTarget)
  }, [dispatch, receivableId])

  const handleClose = React.useCallback(() => {
    setSelectedReceivableID(dispatch, undefined)
    setAnchorEl(null)
  }, [dispatch])

  const onAddNoteButtonClick = React.useCallback(() => {
    setCommentTextForReceivableId(dispatch, receivableId)
    setOpenNoteDialog(dispatch, true)
    handleClose()
  }, [dispatch, handleClose, receivableId])
  const onPrintButtonClick = React.useCallback(() => setOpenPrintDialog(dispatch, true), [dispatch])
  const onAddPhotoButtonClick = React.useCallback(() => setOpenFileSelectDialog(dispatch, true), [dispatch])

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  return React.useMemo(() => (
    <>
      <IconButton
        onClick={handleClick}
        data-testid={`button-moreAction-${receivableId}`}
        key={`moreAction-${receivableId}`}
        size="large">
        <MoreHoriz />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transitionDuration={1000}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '5px',
          backgroundColor: qmColors.darkGrey
        }}>
          <div>
            <StyledIconButton
              onClick={onPrintButtonClick}
              data-testid={'button-printReceivableItem'}
              key={`button-printReceivable-${receivableId}`}
              size="large">
              <PrintOutlined />
            </StyledIconButton>
            <PrintReceivableItemDialog />
          </div>
          <div>
            <StyledIconButton
              onClick={onAddNoteButtonClick}
              data-testid={`button-addComment-${receivableId}`}
              key={`button-addComment-${receivableId}`}
              size="large">
              <CommentOutlined />
            </StyledIconButton>
          </div>
          <div>
            <StyledIconButton
              onClick={onAddPhotoButtonClick}
              data-testid={`button-addPhoto-${receivableId}`}
              key={`button-addPhoto-${receivableId}`}
              size="large">
              <PhotoCameraOutlined />
            </StyledIconButton>
          </div>
          <div>
            <StyledIconButton
              onClick={handleClose}
              data-testid={`button-closeAction-${receivableId}`}
              key={`button-closeAction-${receivableId}`}
              size="large">
              <CloseOutlined />
            </StyledIconButton>
          </div>
        </Box>
      </Popover>
    </>), [receivableId, anchorEl, handleClick, handleClose, id, onAddNoteButtonClick, onAddPhotoButtonClick, onPrintButtonClick, open]
  )
}
