import { Storage } from 'aws-amplify'
import { Backdrop, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Photo } from '../../../graphQL/ReceivablesPhotos'
import { setPhotos, useReceivablesFormDispatch } from '../../../providers/ReceivablesFormProvider'
import AddPhotos from './addPhotos'
import { getImageURLs, ImagePreview } from './getImageURLs'
import PhotoInformation from './photoInformation'
import PhotoPreview from './photoPreview'
import { removePhoto } from './photosHelper'

interface PhotosProps {
  photos: Photo[]
  receivableOrderID: number
}

const Photos: React.FC<PhotosProps> = ({ photos, receivableOrderID }) => {
  const dispatch = useReceivablesFormDispatch()

  const [images, setImages] = useState<ImagePreview[]>([])
  const [photoInfoOpen, setPhotoInfoOpen] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState<ImagePreview>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const getImages = async () => {
      setImages(await getImageURLs(photos))
    }
    getImages()
  }, [photos])

  const onImageClicked = (photoId: number) => {
    setSelectedImage(images.find((i) => i.ReceivableOrderPhotoID === photoId))
    setPhotoInfoOpen(true)
  }

  const onPhotoAdded = async (photo: Photo) => {
    const newImageURL = await getImageURLs([photo])
    const updatedImages = [...images, ...newImageURL]
    setImages(updatedImages)

    setPhotos(dispatch, updatedImages)
  }

  const onImageRemoveClicked = async (photoId: number) => {
    setIsLoading(true)
    try {
      const updatedImages = [...images]
      const index = updatedImages.findIndex((i) => i.ReceivableOrderPhotoID === photoId)
      if (index > -1) {
        updatedImages.splice(index, 1)
        await removePhoto(photoId)
        await Storage.remove(images[index].Path)
        setImages(updatedImages)
      }
      setPhotos(dispatch, updatedImages)
    } catch (e) {
      console.log(e) // TODO - Add notification to errors
    }
    setIsLoading(false)
  }

  const closeDialog = () => setPhotoInfoOpen(false)

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <Backdrop open={isLoading} sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}>
        <CircularProgress />
      </Backdrop>
      <AddPhotos receivableOrderID={receivableOrderID} onPhotoAdded={onPhotoAdded} />
      {images && images.length > 0 && (
        <>
          {selectedImage && <PhotoInformation open={photoInfoOpen} photoInfo={selectedImage} closeDialog={closeDialog} />}
          {images.map((photo) => (
            <PhotoPreview
              key={photo.ReceivableOrderPhotoID}
              fileName={photo.FileName}
              onImageClicked={onImageClicked}
              onImageRemoveClicked={onImageRemoveClicked}
              receivableOrderPhotoID={photo.ReceivableOrderPhotoID}
              url={photo.url}
            />
          ))}
        </>
      )}
    </div>
  )
}

export default Photos
