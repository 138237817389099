import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import React from 'react'
import { ReceivableOrder } from '../graphQL/ReceivableOrder'
import { ReceivableForm } from '../graphQL/ReceivablesForm'
import { theme } from '../theme'
import { ReceivableOrdersProvider } from './ReceivableOrdersProvider'
import { ReceivablesFormProvider } from './ReceivablesFormProvider'
import { UserProvider } from './UserProvider'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

interface Props {
  children: JSX.Element
  receivableOrders?: ReceivableOrder[]
  receivablesForm?: ReceivableForm
}

function ProvidersWrapper({ children, receivableOrders, receivablesForm }: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UserProvider>
            <ReceivableOrdersProvider receivableOrders={receivableOrders}>
              <ReceivablesFormProvider receivablesForm={receivablesForm}>{children}</ReceivablesFormProvider>
            </ReceivableOrdersProvider>
          </UserProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  )
}

export default ProvidersWrapper
